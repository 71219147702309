import React, { Component } from 'react';
import { DatePicker,Row, Col, Select, Button, Spin, Dropdown, Menu, notification } from 'antd';
import moment from 'moment';
import api from '../../Api';
import Cookies from 'js-cookie';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const { Option } = Select;

const { MonthPicker } = DatePicker;
class AllBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startValue: moment().subtract(6,'months'),
            endValue: moment().subtract(1,'months'),
            startYear: null,
            endYear: null,
            startopen: false,
            endOpen: false,
            walletBalance: [],
            timeArr: [],
            isChoosedYear: false,
            minYear:2008,
            maxYear:new Date().getFullYear(),
            startYearArr: [],
            endYearArr: [],
            loading: false
        };
    }
    
    componentDidMount(){
      const { minYear, startValue, endValue } = this.state;
      let params = {start_ym: startValue.format('YYYYMM'),end_ym: endValue.format('YYYYMM'),y_or_m: 'M'};
      this.getYearData(minYear);
      api.get('/report/wallet-balance',{params}).then(
        res => {
            let walletBalance = res.data.data;
            let walletDetailOldArr = res.data.data.isub_wallet_dic;
            let walletDetailNewArr = [];
            for (var key in walletDetailOldArr){
              let newKey = "- "+key;
              walletDetailNewArr[newKey] = walletDetailOldArr[key];
            }
            this.setState({
              timeArr: walletBalance['time_str_s']
            })
            delete walletBalance['isub_wallet_dic'];
            delete walletBalance['time_str_s'];

            for (var akey in walletDetailNewArr){
              walletBalance[akey] = walletDetailNewArr[akey]
              this.setState({
                walletBalance: walletBalance
              })
            }      
        }
      ).catch(
        err => {
          notification.error({
            message: 'Error',
            description: err.response?.data.msg
          });
          if(err.response?.data.code === 401){
            localStorage.removeItem('userInfo');
            localStorage.removeItem('afsToken');
            Cookies.remove('isLogin');
            this.props.history.push('/login');
          }
        }
      )
    }
    
    getYearData = (minyear) =>{
      const { maxYear } = this.state;
      let yearArr = [],endYearArr = [];
      for (let ayear = minyear; ayear <= maxYear-1; ayear++){
        yearArr.unshift(ayear)
      }
      endYearArr.forEach((y)=>{
        if(y>=maxYear){
          endYearArr.unshift(y);
        }
      })
      this.setState({
        startYearArr: yearArr,
        endYearArr: endYearArr
      })
    }

      disabledStartDate = startValue => {
        const { endValue } = this.state;
        if (!startValue || !endValue) {
          return startValue <= moment(new Date('2008/11/01')) || startValue.valueOf() > moment().month(moment().month()-1).endOf('month');
        }
        return startValue <= moment(new Date('2008/11/01')) || startValue.valueOf() > endValue.valueOf();
      };
    
      disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
          return false;
        }
        return endValue.valueOf() <= startValue.valueOf() || endValue.valueOf() > moment().month(moment().month()-1).endOf('month');
      };

      onChange = (field, value) => {
        this.setState({
          [field]: value,
        });
      };
    
      onStartChange = value => {
        this.onChange('startValue', value);
      };
    
      onEndChange = value => {
        this.onChange('endValue', value);
      };
    
      handleStartOpenChange = open => {
        this.setState({ startopen: open });
      };
    
      handleEndOpenChange = open => {
        this.setState({ endOpen: open });
      };

      balanceTitle = (title) => {
        switch (title){
          case 'sv_itc_s':
            return <span style={{fontWeight:'bold'}}>Student Wallets</span>
          case 'tv_itc_s':
            return <span style={{fontWeight:'bold'}}>Teacher Wallets</span>
          case 'av_itc_s':
            return <span style={{fontWeight:'bold'}}>Affiliate Wallets</span>
          case 'isub_wallet_itc_s':
            return <span style={{fontWeight:'bold'}}>italki Wallets</span>
          default:
            return <span style={{marginLeft: '5px'}}>{title}</span>;
        }
          
      }

      chooseYearOrMonth = (value) =>{
        if(value === 'Month'){
          this.setState({
            isChoosedYear: false,
            startYear: null,
            endYear: null
          })
        }else{
          this.setState({
            isChoosedYear: true,
            startValue: null,
            endValue: null
          })
        }
      }

      handleYear = (value) =>{
        const {startYearArr} = this.state;
        let newEndYearArr = [];
        startYearArr.forEach((endY)=>{
          if(endY>=value){
            newEndYearArr.push(endY);
          }
        })
        this.setState({
          endYearArr: newEndYearArr,
          startYear: value
        })
      }

      handleEndYear = (value)=>{
        this.setState({
          endYear: value
        })
      }

      checkData = () =>{
        const { startValue, endValue, startYear, endYear, isChoosedYear} = this.state;
        if(isChoosedYear){
          if(startYear != null && endYear != null){
            return false;
          }
        }else{
          if(startValue != null && endValue != null){
            return false;
          }
        }
        return true;
      }

      searchData = () =>{
        const { startValue, endValue, startYear, endYear, isChoosedYear} = this.state;
        this.setState({
          loading: true
        })
        let params = {};
        if(isChoosedYear){
          params['y_or_m'] = 'Y';
          params['start_ym'] = startYear;
          params['end_ym'] = endYear;
        }else{
          params['y_or_m'] = 'M';
          params['start_ym'] = moment(startValue).format('YYYYMM');
          params['end_ym'] = moment(endValue).format('YYYYMM');
        }
        api.get('/report/wallet-balance',{params}).then(
        res => {
            let walletBalance = res.data.data;
            let walletDetailOldArr = res.data.data.isub_wallet_dic;
            let walletDetailNewArr = [];
            for (var key in walletDetailOldArr){
              let newKey = "- "+key;
              walletDetailNewArr[newKey] = walletDetailOldArr[key];
            }
            this.setState({
              timeArr: walletBalance['time_str_s'],
              loading: false
            })
            delete walletBalance['isub_wallet_dic'];
            delete walletBalance['time_str_s'];

            for (var akey in walletDetailNewArr){
              walletBalance[akey] = walletDetailNewArr[akey]
              this.setState({
                walletBalance: walletBalance
              })
            }      
        }
      ).catch(
        err => {
          this.setState({
            loading: false
          })
          notification.error({
            message: 'Error',
            description: err.response?.data.msg
          });
          if(err.response?.data.code === 401){
            localStorage.removeItem('userInfo');
            localStorage.removeItem('afsToken');
            Cookies.remove('isLogin');
            this.props.history.push('/login');
          }
        }
      )
      }

      downloadAsCSV = () =>{
        const { startValue, endValue, startYear, endYear, isChoosedYear} = this.state;
        let params = {};
        if(isChoosedYear){
          params['y_or_m'] = 'Y';
          params['start_ym'] = startYear;
          params['end_ym'] = endYear;
        }else{
          params['y_or_m'] = 'M';
          params['start_ym'] = moment(startValue).format('YYYYMM');
          params['end_ym'] = moment(endValue).format('YYYYMM');
        }
        let url = `${process.env.REACT_APP_BASE_URL}/report/wallet-balance?y_or_m=${params['y_or_m']}&start_ym=${params['start_ym']}&end_ym=${params['end_ym']}&download_csv=1&token=${localStorage.getItem('afsToken')}`
        window.open(url);
      }

      downloadAsPDF = () =>{
          let target = document.getElementsByClassName("pdfTable")[0];
          html2canvas(target,{scale: 2}).then(function(canvas) {

          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
  
          //一页pdf显示html页面生成的canvas高度;
          let pageHeight = contentWidth / 592.28 * 841.89;
          //未生成pdf的html页面高度
          let leftHeight = contentHeight;
          //页面偏移
          let position = 0;
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = 575.28;
          let imgHeight = (592.28/contentWidth * contentHeight)-20;
  
          let pageData = canvas.toDataURL('image/jpeg', 1.0);
  
          let pdf = new jsPDF('', 'pt', 'a4');
  
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight );
          } else {
              while(leftHeight > 0) {
                  pdf.addImage(pageData, 'JPEG', 10, position+10, imgWidth, imgHeight)
                  leftHeight -= pageHeight;
                  position -= 841.89;
                  //避免添加空白页
                  if(leftHeight > 0) {
                    pdf.addPage();
                  }
              }
          }
          pdf.save("All wallet balance.pdf");
      })
      }

      linkToTrans = (index,item,arrIndex) =>{
        let walletId = item.replace(/[^0-9]/ig,"")
        if(index>=4){
          this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: walletId, date: this.state.timeArr[arrIndex]} });
        }
      }

    render() { 
        const { startValue, endValue, startopen, endOpen, walletBalance, timeArr, isChoosedYear, startYearArr, endYearArr, loading} = this.state;
        return ( 
            <div className="payment">
              <Spin size="large" spinning={loading} className="loading"/>
              <Row gutter={16}>
                <Col span={6}>
                  <p>View By</p>
                  <Select defaultValue={isChoosedYear?'Year':'Month'} style={{width: '100%'}} onChange={this.chooseYearOrMonth}>
                    {/* 暂时取消year选项因为没有对应数据， 所有皆同 */}
                    {/* <Option value='Year'>Year</Option> */}
                    <Option value='Month'>Month</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <div>
                    {
                      isChoosedYear ? (
                      <div>
                          <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{width: '46%'}}>
                              <p>From</p>
                              <Select style={{width: '100%'}} onChange={this.handleYear} placeholder='Choose'>
                                {
                                  startYearArr.length>0 && startYearArr.map((y,index)=>{
                                    return <Option value={y} key={index+'startyear'}>{y}</Option>
                                  })
                                }
                              </Select>
                            </div>
                            <div><p>&nbsp;</p>&nbsp;-&nbsp;</div>
                            <div style={{width: '46%'}}>
                              <p>To</p>
                              <Select style={{width: '100%'}} onChange={this.handleEndYear} placeholder='Choose'>
                                {
                                  endYearArr.length>0 && endYearArr.map((y,index)=>{
                                    return <Option value={y} key={index+'endyear'}>{y}</Option>
                                  })
                                }
                              </Select>
                            </div>
                            
                        </div>
                      </div>
                      ) : (
                      <div>
                        
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                          <div>
                            <p>From</p>
                            <MonthPicker 
                              disabledDate={this.disabledStartDate}
                              // showTime
                              format="YYYY/MM"
                              value={startValue}
                              placeholder="Start"
                              onChange={this.onStartChange}
                              open={startopen}
                              onOpenChange={this.handleStartOpenChange}
                            />
                          </div>
                        <div><p>&nbsp;</p>&nbsp;-&nbsp;</div>
                        <div>
                          <p>To</p>
                          <MonthPicker 
                              disabledDate={this.disabledEndDate}
                              disabled={!startValue}
                              // showTime
                              format="YYYY/MM"
                              value={endValue}
                              placeholder="End"
                              onChange={this.onEndChange}
                              open={endOpen}
                              onOpenChange={this.handleEndOpenChange}
                          />
                        </div>
                        
                        </div>
                      </div>
                      )
                    }
                    
                    
                  </div>
                </Col>
                <Col span={6}>
                  <p>&nbsp;</p>
                  <div style={{float: 'right'}}>
                    <Button disabled={this.checkData()} type="primary" style={{marginRight: '5px'}} onClick={this.searchData}>View</Button>
                    <Dropdown overlay={
                        <Menu>
                          <Menu.Item key="dowloadbalance0">
                            <span onClick={this.downloadAsCSV}>Download as CSV</span>
                          </Menu.Item>
                          <Menu.Item key="dowloadbalance1">
                            <span onClick={this.downloadAsPDF}>Download as PDF</span>
                          </Menu.Item>
                        </Menu>
                      } trigger={['click']}
                        placement="bottomRight">
                        <Button type="primary" >Download</Button>
                      </Dropdown>
                  </div>
                  
                </Col>
              </Row>
              
            
            
            <div style={{width: '100%'}}>
              <table className="pdfTable" style={{marginTop: '20px',overflowX: 'scroll',whiteSpace: 'nowrap',borderRight: 'none'}}>
                <tbody>
                  <tr>
                    <td style={{fontWeight:'bold'}}>ACCOUNT NAME</td>
                    <td></td>
                    <td style={{padding: '0',textAlign: 'center',borderRight: '1px solid #ddd'}}>
                    {
                      timeArr && timeArr.map((item,index)=>{
                        return (
                          <div className='balanceInfo' key={index+'time'} style={{minWidth:'115px',display:'inline-block', fontWeight:'bold',borderRight: '1px solid #ddd',padding: '10px'}}>{item}</div>
                        )
                      })
                    }
                    </td>
                  </tr>
                  {Object.keys(walletBalance).map((item,titleindex)=>{
                    return(
                      <tr key={titleindex+'watr'}>
                          <td style={{width:'250px'}}>{this.balanceTitle(item)}</td>
                          <td>Closing Balance / USD</td>
                          <td style={{padding: '0',borderRight: '1px solid #ddd'}}>{walletBalance[item].map((i,index)=>{
                            return(
                              // <div className={`${index>1 ? 'balanceInfo': ''} ${titleindex<4 ? 'text-500':'canClick'}`} key={index+'watd'} style={{minWidth:'115px',display:'inline-block',borderRight: '1px solid #ddd',padding: '10px',textAlign: 'right'}} onClick={()=>{this.linkToTrans(titleindex,item,index)}}>{this.props.toThousands(i)}</div>
                              <div className={`${index>1 ? 'balanceInfo': ''} ${titleindex<4 ? 'text-500':''}`} key={index+'watd'} style={{minWidth:'115px',display:'inline-block',borderRight: '1px solid #ddd',padding: '10px',textAlign: 'right'}} >{this.props.toThousands(i)}</div>
                              )
                          })}</td>
                      </tr>
                    )
                  })}
                  </tbody>
              </table>
            </div>
                
            </div>
         );
    }
}
 
export default AllBalance;