import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Row, Col, Select, Button, Spin, notification } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import api from '../Api';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface CrePurList {
    key?: number;
    pay_supplier_name: string;
    create_time: string;
    order_id: string;
    usd: string;
    user_id: number;
    status: number;
    [propNames: string]: any;
}

interface PostParams {
    start_ymd: number | string;
    end_ymd: number | string;
    status: number;
    supplier_id?: number;
    page: number;
    page_size: number;
    get_total?: number;
    total?: number;
    download_csv?: number;
}

interface PropsInfo {
    [propNames: string]: any;
}

type PageObj = {
    page_size: number;
    page: number;
}

type paymentMethodList = {
    supplierId?: number,
    name?: string
    value?: number
}

const CreditPurchases: React.FC<{}> = (props: PropsInfo) => {
    const [start_ymd, setStart_ymd] = useState<any>(props.location.state ? moment(props.location.state.startYmd) : moment().month(moment().month() - 1).startOf('month'));
    const [end_ymd, setEnd_ymd] = useState<any>(props.location.state ? moment(props.location.state.endYmd) : moment().month(moment().month() - 1).endOf('month'));
    const [paymentStatus, setPaymentStatus] = useState<string>(props.location.state ? props.location.state.paymentStatus.toString() : '1')
    const [paymentMethod, setPaymentMethod] = useState<string>('')
    const [paymentMethodName, setPaymentMethodName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [crePurList, setCrePurList] = useState<CrePurList[]>([]);
    const [totalItc, setTotalItc] = useState<number>();
    const [pageObj, setPageObj] = useState<PageObj>({ page_size: 50, page: 1 })
    const [dataTotal, setDataTotal] = useState<number>()
    const [paymentMethodList, setPaymentMethodList] = useState<paymentMethodList[]>([]);
    const [providerList, setProviderList] = useState<paymentMethodList[]>([]);

    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: false,
        showTotal: () => `Total: $ ${props.toThousands(totalItc)}, 共${dataTotal}条`,
        pageSize: pageObj.page_size,
        current: pageObj.page,
        total: dataTotal,
        onChange: (current: number) => changePage(current),
    }

    function getData(page: number, type?: string) {
        setLoading(true);
        let params: PostParams = {
            start_ymd: start_ymd.format('YYYY-MM-DD').replace(/-/g, ''),
            end_ymd: end_ymd.format('YYYY-MM-DD').replace(/-/g, ''),
            status: parseInt(paymentStatus),
            page: page,
            page_size: 50,
            get_total: 1
        }
        if (paymentMethod !== '' && paymentMethod !== undefined) params['supplier_id'] = parseInt(paymentMethod);
        if (paymentMethodName !== '' && paymentMethodName !== undefined) params['pay_type'] = paymentMethodName;
        if (dataTotal && type !== 'searchData') {
            params['get_total'] = 0;
            params['total'] = dataTotal;
        }
        api.get('/transaction/itc-purchases', { params }).then(
            res => {
                setLoading(false);
                let resData = res.data;
                setCrePurList(resData.data.data_list);
                setPageObj(resData.paging);
                if (type !== "changePage") {
                    setDataTotal(resData.paging.total)
                }
                if (params['get_total'] === 1) {
                    setTotalItc(resData.data.total_itc);
                }
                // if(type === 'searchData'){
                //     notification.success({
                //         message: 'Success',
                //     });
                // }
            }
        ).catch(
            err => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: err.response.data.msg
                });
                if (err.response.data.code === 401) {
                    localStorage.removeItem('userInfo');
                    localStorage.removeItem('afsToken');
                    Cookies.remove('isLogin');
                    props.history.push('/login');
                }
            }
        )
    }

    useEffect(() => {
        let startYmd: string;
        let endYmd: string;
        let paymentStatus: number;
        let tempPaymentMethod: any;
        let temp: any;
        function getProviderList() {
            let params = {
                name: "PAYMENT_SUPPLIER"
            }
            api.get('/system/get-config', { params }).then(
                res => {
                    temp = Array.from(Object.keys(res.data.data), item => ({ 'name': res.data.data[item], 'supplierId': item }));
                    setProviderList(temp);
                    if (props.location.state) {
                        startYmd = props.location.state.startYmd;
                        endYmd = props.location.state.endYmd;
                        paymentStatus = props.location.state.paymentStatus;
                        tempPaymentMethod = temp.filter((item: paymentMethodList) => item.name === props.location.state.paymentMethod)[0]['supplierId'];
                        if (props.location.state && props.location.state.hasOwnProperty('paymentMethod')) {
                            getPaymentMethod(tempPaymentMethod);
                        }
                        setPaymentMethod(tempPaymentMethod);
                    }
                }
            ).catch(
                err => {
                    console.log('get-config error', err)
                }
            )
        }
        const sleep = (time:number) => {
            return new Promise((resolve, reject) => (
                setTimeout(resolve, time)
            ))
        }
        function getDefaultData() {
            setLoading(true);
            let params: PostParams = {
                start_ymd: startYmd ? startYmd.replace(/-/g, '') : moment().month(moment().month() - 1).startOf('month').format('YYYY-MM-DD').replace(/-/g, ''),
                end_ymd: endYmd ? endYmd.replace(/-/g, '') : moment().month(moment().month() - 1).endOf('month').format('YYYY-MM-DD').replace(/-/g, ''),
                status: paymentStatus ? paymentStatus : 1,
                page: 1,
                page_size: 50,
                get_total: 1
            }
            if (tempPaymentMethod) params['supplier_id'] = parseInt(tempPaymentMethod)
            api.get('/transaction/itc-purchases', { params }).then(
                res => {
                    setLoading(false);
                    let resData = res.data;
                    resData.data.data_list.forEach((item: any, index: number) => {
                        item['key'] = index + item.order_id
                    })
                    setCrePurList(resData.data.data_list);
                    setTotalItc(resData.data.total_itc);
                    setPageObj(resData.paging);
                    setDataTotal(resData.paging.total);
                    // notification.success({
                    //     message: 'Success',
                    // });

                }
            ).catch(
                err => {
                    setLoading(false);
                    notification.error({
                        message: 'Error',
                        description: err.response.data.msg
                    });
                    if (err.response.data.code === 401) {
                        localStorage.removeItem('userInfo');
                        localStorage.removeItem('afsToken');
                        Cookies.remove('isLogin');
                        props.history.push('/login');
                    }
                }
            )
        }
        async function defaultPage() {
            sleep(0).then(() => {
                getProviderList();
                let time = props.location.state && props.location.state.hasOwnProperty('paymentMethod') ? 900 : 0;
                sleep(time).then(() => {
                    getDefaultData();
                })
            })
        }
        defaultPage();
    }, [props])

    function changePage(current: number) {
        getData(current, "changePage")
    }

    function changeDate(value: any) {
        if (value.length > 0) {
            setStart_ymd(value[0]);
            setEnd_ymd(value[1]);
        } else {
            setStart_ymd('');
            setEnd_ymd('');
        }

    }

    function disabledDate(current: any) {
        return current && (current > moment().endOf('day') || current < moment(new Date('2008/11/01')).endOf('day'));
    }

    function searchData() {
        getData(1, 'searchData');
    }

    const getPaymentMethod = (value: string) => {
        let params = {
            name: "PAYMENT_TYPE"
        }
        setPaymentMethodName('');
        let selectedProvider = Number(value)
        api.get('/system/get-config', { params }).then(
            res => {
                let temp: any = Array.from(Object.keys(res.data.data), item => ({ ...res.data.data[item], 'value': item }));
                temp = temp.filter((item: any) => item.supplierId === selectedProvider)
                setPaymentMethodList(temp);
            }
        ).catch(
            err => {
                console.log('get-config error', err)
            }
        )
    }

    return (
        <div>
            <Spin size="large" spinning={loading} className="loading" />
            <div>
                <Row gutter={16}>
                    <Col span={8} className="padding-0-5">
                        <p>Payment Status</p>
                        <Select defaultValue={paymentStatus} style={{ width: '100%' }}
                            onChange={(e: string) => setPaymentStatus(e)}
                        >
                            <Option value='1'>Credit Purchases</Option>
                            <Option value='-1'>Credit Purchases Refund</Option>
                        </Select>
                    </Col>
                    <Col span={8} className="padding-0-5">
                        <p>Provider</p>
                        <Select value={paymentMethod ? paymentMethod : ''} style={{ width: '100%' }} onChange={(e: string) => { setPaymentMethod(e); getPaymentMethod(e); }}>
                            <Option value=''>All</Option>
                            {
                                providerList.map((item, index) => {
                                    return (
                                        <Option key={item.name} value={item.supplierId}>{item.name}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Col>
                    <Col span={8} className="padding-0-5">
                        <p>Payment Method</p>
                        <Select value={paymentMethodName ? paymentMethodName : ''} style={{ width: '100%' }} onChange={(e: string) => { setPaymentMethodName(e); }}>
                            <Option value=''>All</Option>
                            {
                                paymentMethodList.map((item) =>
                                    <Option key={item.name} value={item.value}>
                                        {item.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={8} className="padding-5">
                        <p>Date</p>
                        <RangePicker
                            style={{ width: '100%' }}
                            ranges={{
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                                'Last 30 Days': [moment().subtract(30, 'day'), moment()],
                                'This Year': [moment().startOf('year'), moment().endOf('year')],
                                'Last Year': [moment().year(moment().year() - 1).startOf('year'), moment().year(moment().year() - 1).endOf('year')]
                            }}
                            defaultValue={[start_ymd, end_ymd]}
                            disabledDate={(e) => disabledDate(e)}
                            showTime={false}
                            onChange={(e) => { changeDate(e) }}
                        />
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <p>&nbsp;</p>
                        <Button type="primary" disabled={!start_ymd || !end_ymd} className='float-right' onClick={searchData}>View</Button>
                    </Col>
                </Row>
            </div>
            <Table<CrePurList> dataSource={crePurList} pagination={paginationProps}>
                <Table.Column<CrePurList> key="create_time" title="DATE" render={(crePurList) => (
                    <div>
                        {moment(crePurList.create_time).utc().format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                )} />
                <Table.Column<CrePurList> key="order_id" title="ORDER ID" dataIndex="order_id" />
                <Table.Column<CrePurList> key="usd" title="AMOUNT" dataIndex="usd" />
                <Table.Column<CrePurList> key="user_id" title="USER ID" dataIndex="user_id" />
                <Table.Column<CrePurList> key="pay_supplier_name" title="PROVIDER" render={(selObj) => <span>{selObj.pay_supplier_name}</span>} />
                <Table.Column<CrePurList> key="pay_type_name" title="PAYMENT METHOD" render={(selObj) => <span>{selObj.pay_type_name}</span>} />
                <Table.Column<CrePurList> key="status" title="STATUS" render={(crePurList) => (
                    <div>
                        {crePurList.status === 1 ? 'Credit Purchases' : 'Credit Purchases Refund'}
                    </div>
                )} />
            </Table>
        </div>
    )
}

export default CreditPurchases;