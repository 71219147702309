import React, { PureComponent } from 'react';
import { Table } from 'antd';
import api from '../Api';
import '../Report/Balance/Balance.css';
import moment from 'moment';
import { Row, Col, Input, DatePicker, Select, Button, Spin, notification, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const { Option } = Select;
const columns1 = [
  { title: 'DATE', dataIndex: 'create_time', key: 'create_time' },
  { title: 'WALLET ID', dataIndex: 'effect_userid', key: 'effect_userid' },
  { title: 'WALLET TYPE', key: 'effect_type', render: item => item.effect_userid === 101 ? "Italki" : item.effect_type },
  { title: 'TRANNSACTIONS TYPE', dataIndex: 'show_type_name', key: 'show_type_name' },
  { title: 'AMOUNT', dataIndex: 'amount', key: 'amount' },
  { title: 'RELATION WALLET ID', dataIndex: 'relation_userid', key: 'relation_userid' },
  { title: 'RELATION TYPE', key: 'relation_type', render: item => item.relation_userid < 9999 ? "Italki" : item.relation_type },
];

class ItalkiWalletCreditTrans extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total: null,
      pageNum: null,
      pageSize: null,
      totalData: [],
      inout_statistics: {},
      wallet_id: '',
      relate_wallet_id: null,
      start_ymd: null,
      end_ymd: null,
      wallet_type: '',
      relate_wallet_type: '',
      inout: '',
      relate_wallet_scope: '',
      loading: false,
      transTypes: [],
      transaction_type: '',
      walletIDs: [],
      TYPES: []
    }
  }
  componentDidMount() {
    if (this.props.location.state) {
      const { wallet_id, date, inout, relate_wallet_type, relate_wallet_scope, relate_wallet_id } = this.props.location.state;
      let startD = date.length > 4 ? moment(date, 'YYYYMM').startOf("month").format('YYYYMMDD') : moment(date, 'YYYY').startOf("year").format('YYYYMMDD');
      let endD = date.length > 4 ? moment(date, 'YYYYMM').endOf("month").format('YYYYMMDD') : moment(date, 'YYYY').endOf("year").format('YYYYMMDD');
      this.setState({
        wallet_id: parseInt(wallet_id),
        start_ymd: parseInt(startD),
        end_ymd: parseInt(endD),
        inout: inout,
        relate_wallet_type: relate_wallet_type || '',
        relate_wallet_scope: relate_wallet_scope || '',
        relate_wallet_id: relate_wallet_id || ''
      })
      let params = { wallet_id: parseInt(wallet_id) || '', start_ymd: parseInt(startD) || '', end_ymd: parseInt(endD) || '', inout: inout || null, relate_wallet_type: relate_wallet_type || '', relate_wallet_scope: relate_wallet_scope || '', relate_wallet_id: relate_wallet_id || null }
      this.getData(params, 'initial')
    }
    this.getTransType()
    this.getWalletID()
  }
  async getWalletID() {
    try {
      const { data: { data } } = await api({
        url: "/system/get-config?name=SUB_ACCOUNTS",
        method: 'GET'
      })
      this.setState({ walletIDs: Object.values(data).map(item => ({ value: item.id, label: item.name })) })

    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.msg
      });
    }

  }
  checkWalletType = (i) => {
    switch (i) {
      case 0:
        i = 'Student';
        break;
      case 1:
        i = 'Teacher';
        break;
      case 2:
        i = 'Affiliate';
        break;
      default:
        break;
    }
    return i;
  }

  getData = async (params, type) => {
    this.setState({
      loading: true
    })
    for (var i in params) {
      if (params[i] === '') {
        delete params[i];
      }
    }
    try {
      const res = await api.get('/transaction/sub-wallet', { params })
      console.log(params)
      const dataArr = res.data.data.wallet_transactions;
      dataArr.forEach((item, index) => {
        item['amount'] = `$ ${this.props.toThousands(item.inout * item.trans_amount)}`;
        item['relation_type'] = this.checkWalletType(item['relation_type']);
        item['effect_type'] = this.checkWalletType(item['effect_type']);
      })
      if (type === 'initial') {
        this.setState({
          total: res.data.paging.total,
          inout_statistics: res.data.data.inout_statistics
        })
      }
      this.setState({
        loading: false,
        pageSize: res.data.paging.page_size,
        pageNum: res.data.paging.page,
        totalData: dataArr,
      })

    } catch (error) {
      this.setState({
        loading: false
      })
      notification.error({
        message: 'Error',
        description: error.response.data.msg
      });
    }
  }

  changePage(current) {
    const { wallet_id, start_ymd, end_ymd, wallet_type, relate_wallet_id, relate_wallet_type, inout, relate_wallet_scope, transaction_type } = this.state;
    this.setState({ pageNum: current });
    let params = { wallet_id: wallet_id || '', start_ymd: start_ymd || '', end_ymd: end_ymd || '', wallet_type: wallet_type || '', relate_wallet_id: relate_wallet_id || '', relate_wallet_type: relate_wallet_type || '', inout: inout || '', relate_wallet_scope: relate_wallet_scope || '', page: current, total: this.state.total, show_type: transaction_type || '' }
    this.getData(params);
  }

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDate = (current) => {
    return current && (current > moment().endOf('day') || current < moment(new Date('2008/11/01')).endOf('day'));
  }

  inputWalletId = (value) => {
    const { TYPES } = this.state;
    this.setState({
      wallet_id: parseInt(value) || '',
      transaction_type: '',
      transTypes: TYPES.filter(item => item.account_id.toString() === value.toString())

    })
  }

  inputRelateWalletId = (e) => {
    this.setState({
      relate_wallet_id: parseInt(e.target.value)
    })
  }

  changeDate = (value, dataFormat) => {
    this.setState({
      start_ymd: parseInt(dataFormat[0].replace(/-/g, '')),
      end_ymd: parseInt(dataFormat[1].replace(/-/g, '')),
    })
  }

  changeWalletType = (e) => {
    this.setState({
      wallet_type: e !== '' ? parseInt(e) : ''
    })
  }

  changeRelateWalletType = (e) => {
    this.setState({
      relate_wallet_type: e || ''
    })
  }

  changeInout = (e) => {
    this.setState({
      inout: e !== '' ? parseInt(e) : ''
    })
  }

  changeRelateWalletScope = (e) => {
    this.setState({
      relate_wallet_scope: e
    })
  }


  search = () => {
    const { wallet_id, relate_wallet_id, start_ymd, end_ymd, wallet_type, relate_wallet_type, inout, relate_wallet_scope, transaction_type } = this.state;
    let params = { wallet_id: wallet_id || '', relate_wallet_id: relate_wallet_id || '', start_ymd: start_ymd || '', end_ymd: end_ymd || '', wallet_type: (wallet_type !== '' ? wallet_type : ''), relate_wallet_type: (relate_wallet_type !== '' ? relate_wallet_type : ''), inout: (inout !== '' ? inout : ''), relate_wallet_scope: relate_wallet_scope || '', show_type: transaction_type || '' };
    this.getData(params, 'initial');
  }

  downloadCSV = () => {
    const { wallet_id, relate_wallet_id, start_ymd, end_ymd, wallet_type, relate_wallet_type } = this.state;
    let params = { wallet_id: wallet_id || '', relate_wallet_id: relate_wallet_id || '', start_ymd: start_ymd || '', end_ymd: end_ymd || '', wallet_type: (wallet_type !== '' ? wallet_type : ''), relate_wallet_type: (relate_wallet_type !== '' ? relate_wallet_type : '') };
    let url = `${process.env.REACT_APP_BASE_URL}/transaction/sub-wallet?wallet_id=${params['wallet_id']}&start_ymd=${params['start_ymd']}&end_ymd=${params['end_ymd']}&wallet_type=${params['wallet_type']}&relate_wallet_id=${params['relate_wallet_id']}&relate_wallet_type=${params['relate_wallet_type']}&download_csv=1&token=${localStorage.getItem('afsToken')}`
    window.open(url)
  }
  async getTransType() {
    try {
      const { data: { data } } = await api({
        url: '/system/get-config?name=TRANSACTION_SHOW_TYPE',
        method: 'get',
      })
      const TYPES = Object.values(data).filter(item => item.account_id !== '0').map(item => ({ label: item.name, value: item.show_type, account_id: item.account_id }));
      if (this.props.location.state && this.props.location.state.wallet_id) {
        this.setState({ transTypes: TYPES.filter(item => item.account_id.toString() === this.props.location.state.wallet_id.toString()), TYPES })
      } else {
        this.setState({ transTypes: TYPES, TYPES })
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.msg
      });
    }
  }
  render() {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${this.state.total}条`,
      pageSize: this.state.pageSize,
      current: this.state.pageNum,
      total: this.state.total,
      onChange: (current) => this.changePage(current),
    }
    const { totalData, inout_statistics, wallet_id, start_ymd, end_ymd, loading, inout, relate_wallet_id, relate_wallet_type, relate_wallet_scope, transTypes, walletIDs } = this.state;
    const [start, end] = [moment(String(start_ymd) || ''), moment(String(end_ymd) || '')];
    const disabled = (wallet_id && (new Date(start || null) - new Date(end || null)) / 1000 / 60 / 60 / 24 < 31 && new Date(start).getMonth() === new Date(end).getMonth()) ? false : true;
    return (
      <div>
        <div>
          <Spin size="large" spinning={loading} className="loading" />
          <Row>
            <Col span={8} className="padding-0-5">
              <p>Wallet<span className="color-red">*</span>
                <Tooltip title="Wallet ID is required">
                  <QuestionCircleOutlined className="padding-0-5" />
                </Tooltip>
              </p>
              <Select defaultValue='' value={wallet_id} options={walletIDs} onChange={this.inputWalletId} style={{ width: '100%' }}>
                <Option value=''>Choose</Option>
                {walletIDs.map(item => <Option key={item.value} value={item.value}><Tooltip title={`(${item.value}) ${item.label}`}>({item.value}) {item.label}</Tooltip></Option>)}
              </Select>
            </Col>
            <Col span={8} className="padding-0-5">
              <p>Date <span className="color-red">*</span>
                <Tooltip title="Date is required">
                  <QuestionCircleOutlined className="padding-0-5" />
                </Tooltip>
              </p>
              <RangePicker
                style={{ width: '100%' }}
                disabledDate={this.disabledDate}
                showTime={false}
                format="YYYY-MM-DD"
                onChange={this.changeDate}
                value={start_ymd && end_ymd ? [moment(start_ymd, 'YYYY-MM-DD'), moment(end_ymd, 'YYYY-MM-DD')] : null}
                ranges={{
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                }}
              />
            </Col>
            <Col span={8} className="padding-0-5">
              <p>Wallet Type</p>
              <Select defaultValue='' style={{ width: '100%' }} onChange={this.changeWalletType}>
                <Option value=''>All</Option>
                <Option value='0'>Student Wallet</Option>
                <Option value='1'>Teacher Wallet</Option>
                <Option value='2'>Affiliate Wallet</Option>
              </Select>
            </Col>
          </Row>
          <Row className="margin-top-10">
            <Col span={8} className="padding-0-5">
              <p>Relate Wallet Id</p>
              <Input value={relate_wallet_id} disabled={relate_wallet_scope !== ''} onChange={this.inputRelateWalletId} />
            </Col>
            <Col span={8} className="padding-0-5">
              <p>Relate Wallet Type</p>
              <Select value={relate_wallet_type} defaultValue='' style={{ width: '100%' }} onChange={this.changeRelateWalletType}>
                <Option value=''>All</Option>
                <Option value='0'>Student Wallet</Option>
                <Option value='1'>Teacher Wallet</Option>
                <Option value='2'>Affiliate Wallet</Option>
              </Select>
            </Col>
            <Col span={8} className="padding-0-5">
              <p>Inout</p>
              <Select value={inout && inout.toString()} defaultValue='' style={{ width: '100%' }} onChange={this.changeInout}>
                <Option value=''>All</Option>
                <Option value='1'>Increase</Option>
                <Option value='-1'>Decrease</Option>
              </Select>
            </Col>
          </Row>
          <Row className="margin-top-10">
            <Col span={8} className="padding-0-5">
              <p>Relate Wallet Scope</p>
              <Select value={relate_wallet_scope} disabled={!!relate_wallet_id || relate_wallet_id === 0} defaultValue='' style={{ width: '100%' }} onChange={this.changeRelateWalletScope}>
                <Option value=''>All</Option>
                <Option value='ITALKI'>Italki Sub Wallet</Option>
                <Option value='USER'>User Wallet </Option>
              </Select>
            </Col>
            <Col span={8} className="padding-0-5">
              <p>TRANNSACTION TYPE</p>
              <Select value={this.state.transaction_type} style={{ width: '100%' }} onChange={(v) => { this.setState({ transaction_type: v }) }}>
                <Option value=''>All</Option>
                {transTypes.map(item => <Option value={item.value} key={item.value}><Tooltip title={item.label}>{item.label}</Tooltip></Option>)}
              </Select>
            </Col>
            <Col span={8} className="padding-0-5">
              <p>&nbsp;</p>
              <Button type="primary" disabled={!wallet_id || !start_ymd || !end_ymd} className='float-right' onClick={this.search}>View</Button>
              <Button type="primary" style={{ marginRight: "10px" }} className='float-right' disabled={disabled} onClick={this.downloadCSV}>Download as CSV</Button>
            </Col>
          </Row>
        </div>
        <div>
          {inout_statistics &&
            <table width='100%' className="margin-top-20">
              <tbody>
                <tr>
                  <td className="text-bold bg-ddd text-center padding-10">INCREASE ITC: $ {inout_statistics.increase_itc && this.props.toThousands(inout_statistics.increase_itc)}</td>
                  <td className="text-bold bg-ddd text-center padding-10">INCREASE COUNT: {inout_statistics.increase_count}</td>
                  <td className="text-bold bg-ddd text-center padding-10">DECREASE ITC: $ {inout_statistics.decrease_itc && this.props.toThousands(inout_statistics.decrease_itc)}</td>
                  <td className="text-bold bg-ddd text-center padding-10">DECREASE COUNT: {inout_statistics.decrease_count}</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
        <div>
          <Table
            className="components-table-demo-nested"
            columns={columns1}
            dataSource={totalData}
            pagination={paginationProps}
            rowKey='id'
          />
        </div>

      </div>
    )
  }
}
export default ItalkiWalletCreditTrans;