import React, { Component } from "react";
import {
  DatePicker,
  Row,
  Col,
  Select,
  Button,
  Spin,
  Dropdown,
  Menu,
  notification,
} from "antd";
import moment from "moment";
import api from "../../Api";
import Cookies from "js-cookie";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import './UserTransactions.css'

const { Option } = Select;

const { MonthPicker } = DatePicker;
class UserTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: moment().subtract(6, "months"),
      endValue: moment().subtract(1, "months"),
      startYear: null,
      endYear: null,
      startopen: false,
      endOpen: false,
      walletBalance: [],
      affiliate: [],
      student: [],
      teacher: [],
      timeArr: [],
      isChoosedYear: false,
      minYear: 2008,
      maxYear: new Date().getFullYear(),
      startYearArr: [],
      endYearArr: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { minYear, startValue, endValue } = this.state;
    let params = {
      start_ym: startValue.format("YYYYMM"),
      end_ym: endValue.format("YYYYMM"),
      y_or_m: "M",
    };
    this.getYearData(minYear);
    api
      .get("/report/user-wallet-transaction-type", { params })
      .then((res) => {
        const {
          time_title_arr,
          affiliate_data_arr,
          student_data_arr,
          teacher_data_arr,
        } = res.data.data;
        this.setState({
          timeArr: time_title_arr,
          affiliate: affiliate_data_arr,
          student: student_data_arr,
          teacher: teacher_data_arr,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response?.data.msg,
        });
        if (err.response?.data.code === 401) {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("afsToken");
          Cookies.remove("isLogin");
          this.props.history.push("/login");
        }
      });
  }

  getYearData = (minyear) => {
    const { maxYear } = this.state;
    let yearArr = [],
      endYearArr = [];
    for (let ayear = minyear; ayear <= maxYear - 1; ayear++) {
      yearArr.unshift(ayear);
    }
    endYearArr.forEach((y) => {
      if (y >= maxYear) {
        endYearArr.unshift(y);
      }
    });
    this.setState({
      startYearArr: yearArr,
      endYearArr: endYearArr,
    });
  };

  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return (
        startValue <= moment(new Date("2008/11/01")) ||
        startValue.valueOf() >
          moment()
            .month(moment().month() - 1)
            .endOf("month")
      );
    }
    return (
      startValue <= moment(new Date("2008/11/01")) ||
      startValue.valueOf() > endValue.valueOf()
    );
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return (
      endValue.valueOf() <= startValue.valueOf() ||
      endValue.valueOf() >
        moment()
          .month(moment().month() - 1)
          .endOf("month")
    );
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = (open) => {
    this.setState({ startopen: open });
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  chooseYearOrMonth = (value) => {
    if (value === "Month") {
      this.setState({
        isChoosedYear: false,
        startYear: null,
        endYear: null,
      });
    } else {
      this.setState({
        isChoosedYear: true,
        startValue: null,
        endValue: null,
      });
    }
  };

  handleYear = (value) => {
    const { startYearArr } = this.state;
    let newEndYearArr = [];
    startYearArr.forEach((endY) => {
      if (endY >= value) {
        newEndYearArr.push(endY);
      }
    });
    this.setState({
      endYearArr: newEndYearArr,
      startYear: value,
    });
  };

  handleEndYear = (value) => {
    this.setState({
      endYear: value,
    });
  };

  checkData = () => {
    const { startValue, endValue, startYear, endYear, isChoosedYear } =
      this.state;
    if (isChoosedYear) {
      if (startYear != null && endYear != null) {
        return false;
      }
    } else {
      if (startValue != null && endValue != null) {
        return false;
      }
    }
    return true;
  };

  searchData = () => {
    const { startValue, endValue, startYear, endYear, isChoosedYear } =
      this.state;
    this.setState({
      loading: true,
    });
    let params = {};
    if (isChoosedYear) {
      params["y_or_m"] = "Y";
      params["start_ym"] = startYear;
      params["end_ym"] = endYear;
    } else {
      params["y_or_m"] = "M";
      params["start_ym"] = moment(startValue).format("YYYYMM");
      params["end_ym"] = moment(endValue).format("YYYYMM");
    }
    api
      .get("/report/user-wallet-transaction-type", { params })
      .then((res) => {
        const {
          time_title_arr,
          affiliate_data_arr,
          student_data_arr,
          teacher_data_arr,
        } = res.data.data;
        this.setState({
          timeArr: time_title_arr,
          affiliate: affiliate_data_arr,
          student: student_data_arr,
          teacher: teacher_data_arr,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.response?.data.msg,
        });
        if (err.response?.data.code === 401) {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("afsToken");
          Cookies.remove("isLogin");
          this.props.history.push("/login");
        }
      })
      .finally(_ => {
        this.setState({
          loading: false,
        });
      })
  };

  downloadAsCSV = () => {
    const { startValue, endValue, startYear, endYear, isChoosedYear } =
      this.state;
    let params = {};
    if (isChoosedYear) {
      params["y_or_m"] = "Y";
      params["start_ym"] = startYear;
      params["end_ym"] = endYear;
    } else {
      params["y_or_m"] = "M";
      params["start_ym"] = moment(startValue).format("YYYYMM");
      params["end_ym"] = moment(endValue).format("YYYYMM");
    }
    let url = `${process.env.REACT_APP_BASE_URL}/report/user-wallet-transaction-type?y_or_m=${
      params["y_or_m"]
    }&start_ym=${params["start_ym"]}&end_ym=${
      params["end_ym"]
    }&download_csv=1&token=${localStorage.getItem("afsToken")}`;
    window.open(url);
  };

  downloadAsPDF = () => {
    let target = document.getElementsByClassName("pdfTable")[0];
    html2canvas(target, { scale: 2 }).then(function (canvas) {
      let contentWidth = canvas.width;
      let contentHeight = canvas.height;

      //一页pdf显示html页面生成的canvas高度;
      let pageHeight = (contentWidth / 592.28) * 841.89;
      //未生成pdf的html页面高度
      let leftHeight = contentHeight;
      //页面偏移
      let position = 0;
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      let imgWidth = 575.28;
      let imgHeight = (592.28 / contentWidth) * contentHeight - 20;

      let pageData = canvas.toDataURL("image/jpeg", 1.0);

      let pdf = new jsPDF("", "pt", "a4");

      //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, "JPEG", 10, 10, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          pdf.addImage(
            pageData,
            "JPEG",
            10,
            position + 10,
            imgWidth,
            imgHeight
          );
          leftHeight -= pageHeight;
          position -= 841.89;
          //避免添加空白页
          if (leftHeight > 0) {
            pdf.addPage();
          }
        }
      }
      pdf.save("User Transactions.pdf");
    });
  };

  renderContent = (title, timeArr, content) => {
    return (
      <>
        <tr className="fill-td-cotent text-bold">
          <td>{title}</td>
          {new Array(timeArr.length - 1)
              .fill('')
              .map((_, i) => <td key={i} className="border-left-none"></td>)}
        </tr>
        {content?.length > 0 &&
          content.map((item, index) => (
            <tr key={index} className="fill-td-cotent sub-account">
              {item.map((i, j) => (
                <td key={j}>{i}</td>
              ))}
            </tr>
          ))}
      </>
    );
  };
  render() {
    const {
      startValue,
      endValue,
      startopen,
      endOpen,
      timeArr,
      isChoosedYear,
      startYearArr,
      endYearArr,
      loading,
      affiliate,
      student,
      teacher,
    } = this.state;
    return (
      <div className="payment">
        <Spin size="large" spinning={loading} className="loading" />
        <Row gutter={16}>
          <Col span={6}>
            <p>View By</p>
            <Select
              defaultValue={isChoosedYear ? "Year" : "Month"}
              style={{ width: "100%" }}
              onChange={this.chooseYearOrMonth}
            >
              {/* 暂时取消year选项因为没有对应数据， 所有皆同 */}
              {/* <Option value='Year'>Year</Option> */}
              <Option value="Month">Month</Option>
            </Select>
          </Col>
          <Col span={8}>
            <div>
              {isChoosedYear ? (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ width: "46%" }}>
                      <p>From</p>
                      <Select
                        style={{ width: "100%" }}
                        onChange={this.handleYear}
                        placeholder="Choose"
                      >
                        {startYearArr.length > 0 &&
                          startYearArr.map((y, index) => {
                            return (
                              <Option value={y} key={index + "startyear"}>
                                {y}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                    <div>
                      <p>&nbsp;</p>&nbsp;-&nbsp;
                    </div>
                    <div style={{ width: "46%" }}>
                      <p>To</p>
                      <Select
                        style={{ width: "100%" }}
                        onChange={this.handleEndYear}
                        placeholder="Choose"
                      >
                        {endYearArr.length > 0 &&
                          endYearArr.map((y, index) => {
                            return (
                              <Option value={y} key={index + "endyear"}>
                                {y}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <p>From</p>
                      <MonthPicker
                        disabledDate={this.disabledStartDate}
                        // showTime
                        format="YYYY/MM"
                        value={startValue}
                        placeholder="Start"
                        onChange={this.onStartChange}
                        open={startopen}
                        onOpenChange={this.handleStartOpenChange}
                      />
                    </div>
                    <div>
                      <p>&nbsp;</p>&nbsp;-&nbsp;
                    </div>
                    <div>
                      <p>To</p>
                      <MonthPicker
                        disabledDate={this.disabledEndDate}
                        disabled={!startValue}
                        // showTime
                        format="YYYY/MM"
                        value={endValue}
                        placeholder="End"
                        onChange={this.onEndChange}
                        open={endOpen}
                        onOpenChange={this.handleEndOpenChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col span={6}>
            <p>&nbsp;</p>
            <div style={{ float: "right" }}>
              <Button
                disabled={this.checkData()}
                type="primary"
                style={{ marginRight: "5px" }}
                onClick={this.searchData}
              >
                View
              </Button>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="dowloadbalance0">
                      <span onClick={this.downloadAsCSV}>Download as CSV</span>
                    </Menu.Item>
                    <Menu.Item key="dowloadbalance1">
                      <span onClick={this.downloadAsPDF}>Download as PDF</span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button type="primary">Download</Button>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <div style={{ width: "100%" }}>
          <table
            className="pdfTable"
            style={{
              marginTop: "20px",
              overflowX: "scroll",
              whiteSpace: "nowrap",
              borderRight: "none",
            }}
          >
            <tbody>
              <tr>
                {timeArr?.length > 0 &&
                  timeArr.map(item => {
                    return (
                      <td
                        key={item}
                        style={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        {item}
                      </td>
                    );
                  })}
              </tr>
              {timeArr?.length > 0 && this.renderContent('Student Wallets', timeArr,student)}
              {timeArr?.length > 0 && this.renderContent('Teacher Wallets', timeArr,teacher)}
              {timeArr?.length > 0 && this.renderContent('Affiliate Wallets', timeArr,affiliate)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UserTransactions;
