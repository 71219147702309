import React, { Component } from 'react';
import '../../Balance/Balance.css';
import moment from 'moment';

class StWalletMovementDe extends Component {
    linkToTrans = (type, itc, item) => {
        let month = this.props.month < 10 ? '0' + this.props.month.toString() : this.props.month.toString();
        if (itc > 0) {
            if (type === 'topTitle') {
                this.props.history.push({ pathname: "/transactions/credit-purchases", state: { startYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'), endYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), paymentStatus: -1 } });
            } else {
                this.props.history.push({ pathname: "/transactions/credit-purchases", state: { startYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'), endYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), paymentStatus: -1, paymentMethod: item } });
            }
        }

    }

    render() {
        return (
            <div>
                <table width='100%'>
                    <tbody>
                        <tr>
                            <td className="text-bold bg-lightddd">Decrease</td>
                            <td className="text-bold bg-lightddd text-right">${this.props.toThousands(this.props.stWalletMovement.decrease)}</td>
                        </tr>
                        <tr>
                            <td className="text-500">Credit Cash Refund</td>
                            <td className={`text-500 text-right ${this.props.stWalletMovement.in_purchase > 0 ? 'canClick' : ''}`} onClick={() => { this.linkToTrans('topTitle', this.props.stWalletMovement.de_purchase) }}>${this.props.toThousands(this.props.stWalletMovement.de_purchase)}</td>
                        </tr>
                        {
                            this.props.stWalletMovement.de_purchases_dic && Object.keys(this.props.stWalletMovement.de_purchases_dic).map((item, i) => {
                                return (
                                    <tr key={i + 'stwadp'} className="color-light">
                                        <td>- {item}</td>
                                        <td className={`text-right ${this.props.stWalletMovement.de_purchases_dic[item] > 0 ? 'canClick' : ''}`} onClick={() => { this.linkToTrans('childTitle', this.props.stWalletMovement.de_purchases_dic[item], item) }}>${this.props.toThousands(this.props.stWalletMovement.de_purchases_dic[item])}</td>
                                    </tr>)
                            })
                        }
                        <tr>
                            <td className="text-500">To italki Wallets</td>
                            <td className="text-500 text-right">${this.props.toThousands(this.props.stWalletMovement.to_sub_wallet.total_itc)}</td>
                        </tr>
                        {
                            this.props.stWalletMovement.to_sub_wallet.account_dic && Object.keys(this.props.stWalletMovement.to_sub_wallet.account_dic).map((item, i) => {
                                return (
                                    <tr key={i + 'stwats'} className="color-light">
                                        <td>- {item}</td>
                                        <td className="text-right">${this.props.toThousands(this.props.stWalletMovement.to_sub_wallet.account_dic[item])}</td>
                                    </tr>)
                            })
                        }
                        <tr>
                            <td className="text-500">To Teacher Wallets</td>
                            <td className="text-500 text-right">${this.props.toThousands(this.props.stWalletMovement.to_teacher + this.props.stWalletMovement.to_diff_user)}</td>
                        </tr>
                        <tr className="color-light">
                            <td>- Same User Transfer</td>
                            <td className="text-right">${this.props.toThousands(this.props.stWalletMovement.to_teacher)}</td>
                        </tr>
                        <tr className="color-light">
                            <td>- Different User Transfer</td>
                            <td className="text-right">${this.props.toThousands(this.props.stWalletMovement.to_diff_user)}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        )
    }
}

export default StWalletMovementDe;