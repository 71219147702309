import React, { useEffect, useState } from 'react';
import api from '../../Api';
import { Button, Table, Row, Col, Select } from 'antd';
import { commonFilter } from '../../CommonFunction';
import moment from 'moment';

const { Option } = Select;


const CreditBudgetUsage = () => {
    const [data, setData] = useState({
        credit_budget_usage: [],
        time_title_arr: []
    });
    const [loading, setLoading] = useState(false);
    const [tableColumns, setTableColums] = useState([])
    const [searchParam, setSearchParam] = useState({ year: moment().format('YYYY') });
    const [searchYear, setSearchYear] = useState(moment().format('YYYY'));

    const getDefaultData = (searchParam) => {
        setLoading(true);
        let params = {
            ...searchParam
        }

        api.get('/report/credit-budget-usage', { params }).then(
            res => {
                const monthArr = [
                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
                ]

                let tempColumns = [
                    {
                        title: 'All figures in Credit(USD)',
                        key: 'all_figures',
                        children: [
                            {
                                title: 'Cost Center',
                                render: (item) => (
                                    item.cost_center
                                ),
                                key: 'cost_center'
                            },
                            {
                                title: 'Annual Budget',
                                render: (item) => (
                                    `$${item.annual_budget === 0 ? '0.00' : commonFilter('centToUsd', item.annual_budget)}`
                                ),
                                key: 'annual_budget',
                            },
                            {
                                title: 'Budget to date',
                                key: 'budget_to_date',
                                render: (item) => (
                                    `$${item.budget_to_date === 0 ? '0.00' : commonFilter('centToUsd', item.budget_to_date)}`
                                )
                            },
                            {
                                title: 'Actual to date',
                                key: 'actual_to_date',
                                render: (item) => (
                                    `$${item.actual_to_date === 0 ? '0.00' : commonFilter('centToUsd', item.actual_to_date)}`
                                )
                            },
                            {
                                title: 'Variance to date',
                                key: 'variance_itc',
                                render: (item) => (
                                    `$${item.variance_itc === 0 ? '0.00' : commonFilter('centToUsd', item.variance_itc)}`
                                )
                            },
                            {
                                title: 'Variance(%)',
                                key: 'variance_percent',
                                render: (item) => (
                                    item.variance_percent
                                        ? `${commonFilter('usdToCent', item.variance_percent)} %`
                                        : '0 %'
                                )
                            },
                            {
                                title: 'Utilization(%)',
                                key: 'utilization_percent',
                                render: (item) => (
                                    item.utilization_percent
                                        ? `${commonFilter('usdToCent', item.utilization_percent)} %`
                                        : '0 %'
                                )
                            }
                        ]
                    }
                ]
                const reducer = (accumulator, currentValue, idx) => {
                    if (!!res.data.data && !!res.data.data.time_title_arr && currentValue < res.data.data.credit_budget_usage[0].monthly_budget_usage.length) {
                        accumulator = [
                            ...accumulator,
                            {
                                title: res.data.data.time_title_arr[currentValue],
                                children: [
                                    {
                                        title: 'Budget',
                                        render: (item) => (
                                            item.monthly_budget_usage[idx]
                                                ? `$${item.monthly_budget_usage[idx].budget === 0 ? '0.00' : commonFilter('centToUsd', item.monthly_budget_usage[idx].budget)}`
                                                : ''
                                        ),
                                        key: `${idx}_budget`
                                    },
                                    {
                                        title: 'Actual',
                                        key: `${idx}_actual`,
                                        render: (item) => (
                                            item.monthly_budget_usage[idx]
                                                ? `$${item.monthly_budget_usage[idx].actual === 0 ? '0.00' : commonFilter('centToUsd', item.monthly_budget_usage[idx].actual)}`
                                                : ''
                                        )
                                    },
                                    {
                                        title: 'Variance',
                                        key: `${idx}_Variance`,
                                        render: (item) => (
                                            item.monthly_budget_usage[idx]
                                                ? `$${item.monthly_budget_usage[idx].variance === 0
                                                    ? '0.00'
                                                    : commonFilter('centToUsd', item.monthly_budget_usage[idx].variance)}`
                                                : item.monthly_budget_usage[idx]
                                        )
                                    }
                                ]
                            }
                        ]
                        return accumulator;
                    } else {
                        return accumulator;
                    }

                }
                let tempArr = monthArr.reduce((all, current, idx) => reducer(all, current, idx), tempColumns);
                setData(res.data.data)
                setTableColums(tempArr);
                setLoading(false);
            }
        ).catch(
            err => {
                setLoading(false);
            }
        )

    }

    const open = () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/report/credit-budget-usage?year=${searchParam.year}&download_csv=1&token=${localStorage.getItem('afsToken')}`)
    }

    useEffect(() => {
        getDefaultData(searchParam);
    }, [searchParam])


    const yearArr = [2009].reduce((all, current) => {
        let temp = [];
        for (let i = all; i >= current; i--) {
            temp.push(i)
        }
        return temp;
    }, Number(moment().format('YYYY')))

    return (
        <>
            <Row>
                <Col span={1}>
                    <span className="vertically-end padding-top-5">Year</span>
                </Col>
                <Col span={6}>

                    <Select
                        defaultValue={moment().format('YYYY')}
                        onChange={(value) => {
                            setSearchYear(value)
                        }}
                        placeholder="Choose"
                        style={{ width: '100%' }}>
                        {
                            yearArr.map((item) => (
                                <Option key={item} value={item}>
                                    {item}
                                </Option>
                            ))
                        }
                    </Select>
                </Col>
                <Col span={12} className="display-flex">
                    <div className="padding-0-10">
                        <Button
                            type="primary"
                            onClick={() => setSearchParam({
                                year: searchYear
                            })}>
                            View
                        </Button>
                    </div>

                </Col>
                <Col span={5}>
                    <div className="padding-0-10 float-right">
                        <Button
                            type="primary"
                            onClick={() => open()}>
                            Download as CSV
                        </Button>
                    </div>
                </Col>
            </Row>
            <Table
                loading={loading}
                columns={tableColumns}
                pagination={false}
                dataSource={
                    data.credit_budget_usage
                }
                bordered={true}
                rowKey={(item) => { return item.cost_center }}
            />
        </>
    )
}

export default CreditBudgetUsage;