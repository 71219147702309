import React from 'react';
import '../../Balance/Balance.css';

const AfWalletMovementCB = props =>{
    return (
        <div>
            <table width='100%'>
                <tbody>
                    <tr>
                        <td className="text-bold bg-ddd">Closing Balance<br/>(OB+Incr-Decr)</td>
                        <td className="text-bold bg-ddd text-right" style={{color: props.afWalletMovement.closing_balance_figure_up !== props.afWalletMovement.closing_balance ? 'red' : ''}}>${props.toThousands(props.afWalletMovement.closing_balance_figure_up)}</td>
                    </tr>
                    <tr>
                        <td className="text-bold bg-ddd">Closing Balance<br/>(Snapshot)</td>
                        <td className="text-bold bg-ddd text-right">${props.toThousands(props.afWalletMovement.closing_balance)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default AfWalletMovementCB;