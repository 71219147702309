import React from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

const AuthRouter = ({ component: Component, ...rest}) => {
    const isLogined = Cookies.get('isLogin') === '1' ? true : false;
	return <Route {...rest} render={props => (isLogined ? <Component {...props} /> : <Redirect to={'/login'} />)} />;
};

export default withRouter(AuthRouter);