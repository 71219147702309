import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Form, Button, Table, DatePicker, message, Statistic } from 'antd';
import moment from 'moment';
import './index.css'
import { CountrySelect, getData as getCountryData } from '../../../share/Country';
import { PaymentMethodSelector } from '../../../share/PaymentMethod';
import { PaymentSupplierSelect } from '../../../share/PaymentSupplier';
import api from '../../Api';

function useCountryDataMap() {
  const [data, setData] = useState(() => ({}))

  useEffect(() => { getCountryData().then(d => setData(d.reduce((r, c) => ({ ...r, [c.value]: c.label }), Object.create(null)))) }, [])

  return data
}

function useMaxTableHeight() {
  const [h, setHeight] = useState(() => window.document.body.clientHeight - 580)

  useEffect(() => {
    const cb = () => {
      setHeight(window.document.body.clientHeight - 580)
    }

    window.addEventListener('resize', cb)
    return () => window.removeEventListener('resize', cb)
  }, [])

  return h
}

const DEFAULT_PAGE_SIZE = 10

export default function SalesTax() {
  const [fetching, setFetching] = useState(() => true)
  const [_pagination, setPagination] = useState(() => ({ pageSize: DEFAULT_PAGE_SIZE }))
  const [dataSource, setDataSource] = useState(() => ([]))
  const [statistics, setStatistics] = useState(() => ({})) // {total_cash_amount: [], total_tax_amount: []}
  const [totalItc, setTotalItc] = useState(() => 0)

  const COUNTRY_DATA_MAP = useCountryDataMap()

  const DEFAULT_PICKER_VALUE = useMemo(() => [moment().add(-1, 'months').startOf('month'), moment().add(-1, 'months').endOf('month')], [])

  const COLUMNS = useMemo(() => [
    {
      title: 'DATE',
      width: 200,
      dataIndex: 'create_time',
      fixed: 'left',
    },
    {
      title: 'ORDER ID',
      width: 140,
      dataIndex: 'order_id',
      fixed: 'left',
    },
    {
      title: 'CREDIT AMOUNT',
      width: 150,
      dataIndex: 'usd',
      render: (usd) => `$${usd}`,
      align: 'right'
    },
    {
      title: 'CURRENCY',
      width: 100,
      dataIndex: 'currency',
      align: 'center'
    },
    {
      title: 'CASH AMOUNT',
      width: 140,
      dataIndex: 'cash_amount',
      align: 'right'
    },
    {
      title: 'TAX AMOUNT',
      width: 130,
      dataIndex: 'tax_amount',
      align: 'right'
    },
    {
      title: 'COUNTRY/REGION',
      width: 170,
      dataIndex: 'country_id',
      render: (none, item) => `${COUNTRY_DATA_MAP[item.country_id]}${item.region ? '/' + item.region : ''}`,
      align: 'center'
    },
    {
      title: 'PROVIDER',
      dataIndex: 'pay_supplier_name',
      width: 100,
      align: 'center'
    },
    {
      title: 'PAYMENT METHOD',
      width: 250,
      dataIndex: 'pay_type_name',
      align: 'center'
    },
  ], [COUNTRY_DATA_MAP])


  const MAX_TABLE_Y = useMaxTableHeight()
  const TABLE_SCROLL = useMemo(() => ({
    x: COLUMNS.reduce((r, c) => r + c.width, 0),
    y: MAX_TABLE_Y
  }), [COLUMNS, MAX_TABLE_Y])

  const DISABLED_DATE_RANGE_PICKER = useCallback((current) => {
    return current && (current > moment().add(-1, 'months').endOf('month') || current < moment().add(-12, 'months').startOf('month'));
  }, [])

  const formDataRef = useRef({
    start_ymd: DEFAULT_PICKER_VALUE[0].format('YYYYMMDD'),
    end_ymd: DEFAULT_PICKER_VALUE[1].format('YYYYMMDD')
  })

  const updateFormData = useCallback((field, value) => {
    if (value !== null) {
      formDataRef.current[field] = value
    } else {
      delete formDataRef.current[field]
    }
  }, [])

  const handleCountryChange = useCallback((countryValue) => {
    updateFormData('country', countryValue || null)
  }, [updateFormData])

  const handleProviderChange = useCallback((supplierIdValue) => {
    updateFormData('supplier_id', supplierIdValue !== undefined ? supplierIdValue : null)
  }, [updateFormData])

  const handlemPaymentMethodChange = useCallback((paymentMethodValue) => {
    updateFormData('pay_type', paymentMethodValue !== undefined ? paymentMethodValue : null)
  }, [updateFormData])

  const handleDateRangeChange = useCallback((_momentValue, strValue) => {
    const [start_ymd, end_ymd] = strValue.filter(v => !!v).length === 2 ? strValue : [null, null]
    updateFormData('start_ymd', start_ymd)
    updateFormData('end_ymd', end_ymd)
  }, [updateFormData])

  // TODO
  // const handleDownloadData = useCallback(() => { }, [])

  const handleFetchData = useCallback((page = 1, total = 0) => {
    setFetching(true)

    return api.get('/transaction/taxes', {
      params: {
        page,
        page_size: DEFAULT_PAGE_SIZE,
        // @hui 接口要求 仅第一页 & get_total=1 才会返回 total 统计
        get_total: !total || page === 1 ? 1 : 0,
        // @hui 接口要求 非第一页的 要传 total 字段
        ...(total && page !== 1 ? { total } : {}),
        ...formDataRef.current
      }
    })
      .then(res => {
        setPagination(p => ({ ...p, total: res.data.paging.total, current: res.data.paging.page }))

        setDataSource(res.data.data.data_list)

        if (page === 1) {
          setStatistics(res.data.data.statistics?.reduce((r, c) => {
            r.total_cash_amount.push([c.currency, c.total_cash_amount])
            r.total_tax_amount.push([c.currency, c.total_tax_amount])
            return r
          }, { total_cash_amount: [], total_tax_amount: [] }))

          setTotalItc(res.data.data.total_itc)
        }

      })
      .catch(() => message.error('fetch data error'))
      .finally(() => setFetching(false))
  }, [])

  useEffect(() => {
    handleFetchData()
  }, [])

  const pagination = useMemo(() => ({
    ..._pagination,
    onChange: (page) => handleFetchData(page, _pagination.total)
  }), [_pagination, handleFetchData])

  // const COL_PROPS = useMemo(() => {
  //   const len = statistics.total_cash_amount.length + statistics.total_tax_amount.length + 1
  //   const shul = 24 / len
  // }, [statistics])

  return (
    <div className='sale-tax'>
      <Form className='form'>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label='Country'>
              <CountrySelect onChange={handleCountryChange} />
            </Form.Item>
          </Col>

          {/* @hui 说没了 */}
          {/* <Col span={8}>
            <Form.Item label='Payment Status'>
              <PaymentStatusSelect />
            </Form.Item>
          </Col> */}

          <Col span={8}>
            <Form.Item label='Provider'>
              <PaymentSupplierSelect onChange={handleProviderChange} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label='Payment Method'>
              <PaymentMethodSelector onChange={handlemPaymentMethodChange} />
            </Form.Item>
          </Col>
        </Row>

        <Row className='form-center' gutter={24} style={{ marginTop: '-10px' }}>
          <Col span={8}>
            <Form.Item label='Date'>
              <DatePicker.RangePicker
                className='w-full'
                disabledDate={DISABLED_DATE_RANGE_PICKER}
                showTime={false}
                format="YYYYMMDD"
                onChange={handleDateRangeChange}
                defaultValue={DEFAULT_PICKER_VALUE}
              />
            </Form.Item>
          </Col>

          <Col className='form-buttons' span={8} offset={8}>
            {/* <Button type='primary' onClick={handleDownloadData}>Download</Button> */}
            <Button type='primary' loading={fetching} onClick={() => handleFetchData(1)}>View</Button>
          </Col>
        </Row>
      </Form>

      <Row className='statistics' style={{ marginTop: '20px' }}>
        <Col span={4}>
          <Statistic title='ITC' value={totalItc} precision={2} />
        </Col>

        <Col span={4} offset={1}>
          <Statistic title={`CASH (${statistics.total_cash_amount?.[0]?.[0] || ''})`} value={statistics.total_cash_amount?.[0]?.[1] || 0} precision={2} />
        </Col>
        <Col span={4} offset={1}>
          <Statistic title={`CASH (${statistics.total_cash_amount?.[1]?.[0] || ''})`} value={statistics.total_cash_amount?.[1]?.[1] || 0} precision={2} />
        </Col>

        <Col span={4} offset={1}>
          <Statistic title={`TAX (${statistics.total_tax_amount?.[0]?.[0] || ''})`} value={statistics.total_tax_amount?.[0]?.[1] || 0} precision={2} />
        </Col>
        <Col span={4} offset={1}>
          <Statistic title={`TAX (${statistics.total_tax_amount?.[1]?.[0] || ''})`} value={statistics.total_tax_amount?.[1]?.[1] || 0} precision={2} />
        </Col>
      </Row>

      <Table className='table' rowKey='order_id' columns={COLUMNS} dataSource={dataSource} pagination={pagination} scroll={TABLE_SCROLL} loading={fetching} />
    </div>
  )
}
