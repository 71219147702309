import React, { Component } from 'react';
import { Row, Col, Select, Button, Spin, Dropdown, Menu, Modal, notification } from 'antd';
import '../Balance/Balance.css';
import api from '../../Api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Cookies from 'js-cookie';
import StWalletMovementIc from './StudentWalletMovement/Increase';
import TeWalletMovementIc from './TeacherWalletMovement/Increase';
import AfWalletMovement from './AffiliateWalletMovement/Increase';
import StWalletMovementDe from './StudentWalletMovement/Decrease';
import TeWalletMovementDe from './TeacherWalletMovement/Decrease';
import AfWalletMovementDe from './AffiliateWalletMovement/Decrease';
import StWalletMovementCB from './StudentWalletMovement/ClosingBalance';
import TeWalletMovementCB from './TeacherWalletMovement/ClosingBalance';
import AfWalletMovementCB from './AffiliateWalletMovement/ClosingBalance';
const { Option } = Select;

class CreditMovements extends Component {
    constructor(props) {
        super(props);
        this.state = {
          stWalletMovement: null,
          teWalletMovement: null,
          afWalletMovement: null,
          downloadSt:false,
          downloadTe:false,
          downloadAf:false,
          minYear: 2008,
          maxYear: new Date().getFullYear(), //最大年份，也是当前默认选中年份
          defaultMonth: new Date().getMonth(),//默认选中当前月上一个月
          year: [],
          month: [1,2,3,4,5,6,7,8,9,10,11,12],
          isChoosedYear: false,
          choosedYear: null,
          choosedMonth: null,
          loading: false,
          colWidth: 6,
          visible: false,
          downloadPdfName: null
        };
    }

    UNSAFE_componentWillMount(){
      const { defaultMonth,maxYear } = this.state;
      if(defaultMonth === 0){
        this.setState({
          defaultMonth: 12,
          maxYear: maxYear-1
        })
      }
    }

    componentDidMount(){
      const { minYear, maxYear, defaultMonth } = this.state;
      this.getYearData(minYear);
      let params = {year:maxYear,month:defaultMonth}
      api.get('/report/user-itc-movement',{params}).then(
        res => {
           this.setState({
            stWalletMovement: res.data.data.stu_itc_movements,
            teWalletMovement: res.data.data.tea_itc_movements,
            afWalletMovement: res.data.data.aff_itc_movements,
           })
        }
      ).catch(
        err => {
          notification.error({
            message: 'Error',
            description: err.response?.data.msg
          });
          if(err.response?.data.code === 401){
            localStorage.removeItem('userInfo');
            localStorage.removeItem('afsToken');
            Cookies.remove('isLogin');
            this.props.history.push('/login');
          }
        }
      )
    }

    getYearData = (minyear) =>{
      const { maxYear } = this.state;
      let yearArr = [];
      for (let ayear = minyear; ayear <= maxYear; ayear++){
        yearArr.unshift(ayear)
      }
      this.setState({
        year: yearArr,
      })
    }

    chooseYearOrMonth = (value) =>{
      const { maxYear, year } = this.state;
      const currentY = new Date().getFullYear();
      if(value === 'Month'){
        if(year.indexOf(currentY) === -1){
          year.unshift(currentY);
          const haveCurrentMaxYear = currentY;
          this.setState({
            isChoosedYear: false,
            maxYear: haveCurrentMaxYear,
            year: year
          })
        }else{
          this.setState({
            isChoosedYear: false
          })
        }
      }else{
        if(year.indexOf(currentY) !== -1){
          const onlyMaxYear = maxYear-1;
          year.shift();
          this.setState({
            isChoosedYear: true,
            choosedMonth: null,
            maxYear: onlyMaxYear,
            year: year,
            choosedYear: null
          })
        }else{
          this.setState({
            isChoosedYear: true,
            choosedMonth: null,
          })
        }
      }
    }

    handleYear = (value) =>{
      const { month } = this.state;
      if(value === 2008){
        month.splice(0,10);
        this.setState({
          month: month
        })
      }else{
        this.setState({
          month: [1,2,3,4,5,6,7,8,9,10,11,12]
        })
      }
      this.setState({
        choosedYear: value
      })
    }

    handleMonth = (value) =>{
      this.setState({
        choosedMonth: value
      })
    }

    searchData = () =>{
      const { isChoosedYear, choosedYear, choosedMonth, maxYear, defaultMonth } = this.state;
      this.setState({
        loading: true
      })
      let params = {year: choosedYear||maxYear};
      if(!isChoosedYear){
        params['month'] = choosedMonth || defaultMonth;
      }
      
      api.get('/report/user-itc-movement',{params}).then(
        res => {
           this.setState({
            stWalletMovement: res.data.data.stu_itc_movements,
            teWalletMovement: res.data.data.tea_itc_movements,
            afWalletMovement: res.data.data.aff_itc_movements,
            loading: false
           })
        }
      ).catch(
        err => {
          this.setState({
            loading: false
          })
          notification.error({
            message: 'Error',
            description: err.response?.data.msg
          });
          if(err.response?.data.code === 401){
            localStorage.removeItem('userInfo');
            localStorage.removeItem('afsToken');
            Cookies.remove('isLogin');
            this.props.history.push('/login');
          }
        }
      )
    }

    handleCancel = () =>{
      this.setState({ visible: false });
    }

    downloadAsCSV = () =>{
      const { isChoosedYear, choosedYear, choosedMonth, maxYear, defaultMonth } = this.state;
      let params = {year: choosedYear||maxYear};
      if(!isChoosedYear){
        params['month'] = choosedMonth || defaultMonth;
      }
      let url = `${process.env.REACT_APP_BASE_URL}/report/user-itc-movement?year=${params['year']}${params['month'] ? `&month=${params['month']}` : ''}&download_csv=1&token=${localStorage.getItem('afsToken')}`
      window.open(url);
    }

    downloadAsPDF = (tableName) =>{
      this.setState({ visible: true });
      if(this.state.colWidth !== 24){
        this.setState({
          colWidth : 24,
        })
      }
      if(tableName === 'st'){
        this.setState({
          downloadSt: false,
          downloadTe: true,
          downloadAf: true,
          downloadIt: true,
          downloadPdfName: 'Student Wallets'
        })
      }else if(tableName === 'te'){
        this.setState({
          downloadSt: true,
          downloadTe: false,
          downloadAf: true,
          downloadIt: true,
          downloadPdfName: 'Teacher Wallets'
        })
      }else if(tableName === 'aff'){
        this.setState({
          downloadSt: true,
          downloadTe: true,
          downloadAf: false,
          downloadIt: true,
          downloadPdfName: 'Affiliate Wallets'
        })
      }
    }
    handleOk = () =>{
      const { downloadPdfName } = this.state;
        let target = document.getElementsByClassName("pdfTable")[0];
        html2canvas(target,{scale: 2,dpi : window.devicePixelRatio * 2}).then(function(canvas) {
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
   
          //一页pdf显示html页面生成的canvas高度;
          let pageHeight = contentWidth / 592.28 * 841.89;
          //未生成pdf的html页面高度
          let leftHeight = contentHeight;
   
          //页面偏移
          let position = 0;
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = 575.28;
          let imgHeight = (592.28/contentWidth * contentHeight)-20;
   
          let pageData = canvas.toDataURL('image/jpeg', 1.0);
   
          let pdf = new jsPDF('', 'pt', 'a4');
   
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight );
          } else {
              while(leftHeight > 0) {
                  pdf.addImage(pageData, 'JPEG', 10, position+10, imgWidth, imgHeight)
                  leftHeight -= pageHeight;
                  position -= 841.89;
                  //避免添加空白页
                  if(leftHeight > 0) {
                    pdf.addPage();
                  }
              }
          }
          pdf.save(downloadPdfName);
        
      })
    }
    render() { 
      const { stWalletMovement, teWalletMovement, afWalletMovement, year, month,maxYear, defaultMonth, isChoosedYear, loading, choosedYear, choosedMonth, downloadSt, downloadTe, downloadAf, colWidth } = this.state;
      const { history } = this.props;
      return ( 
            <div className="wallet">
              <Spin size="large" spinning={loading} className="loading"/>
              <div style={{paddingBottom: '30px',borderBottom: '1px solid #ddd'}}>
                <Row gutter={16}>
                  <Col span={6}>
                    <p>View By</p>
                    <Select defaultValue={isChoosedYear?'Year':'Month'} style={{width: '100%'}} onChange={this.chooseYearOrMonth} >
                      {/* <Option value='Year'>Year</Option> */}
                      <Option value='Month'>Month</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p>Year</p>
                    <Select value={choosedYear || maxYear} style={{width: '100%'}} onChange={this.handleYear} placeholder='Choose'>
                      {
                        year.length>0 && year.map((y,i)=>{
                          return <Option key={i+'year'} value={y}>{y}</Option>
                        })
                      }
                    </Select>
                  </Col>
                  <Col span={6}>
                    {
                      !isChoosedYear && 
                      <div>
                      <p>Month</p>
                      <Select defaultValue={defaultMonth} style={{width: '100%'}} onChange={this.handleMonth} placeholder='Choose'>
                        {
                          month.length>0 && month.map((m,i)=>{
                            return <Option key={i+'month'} value={m}>{m}</Option>
                          })
                        }
                      </Select>
                      </div>
                    }
                  </Col>
                  <Col span={6}>
                    <p>&nbsp;</p>
                    <div style={{float: 'right'}}>
                      <Button type="primary" style={{marginRight: '5px'}} onClick={this.searchData}>View</Button>
                      <Dropdown overlay={
                        <Menu>
                          <Menu.Item key="dowloadmovement0">
                            <span onClick={this.downloadAsCSV}>Download as CSV</span>
                          </Menu.Item>
                          <Menu.Item key="dowloadmovement1">
                            <span onClick={()=>{this.downloadAsPDF('st')}}>Download as PDF(St Wallet)</span>
                          </Menu.Item>
                          <Menu.Item key="dowloadmovement2">
                            <span onClick={()=>{this.downloadAsPDF('te')}}>Download as PDF(Te Wallet)</span>
                          </Menu.Item>
                          <Menu.Item key="dowloadmovement3">
                            <span onClick={()=>{this.downloadAsPDF('aff')}}>Download as PDF(Aff Wallet)</span>
                          </Menu.Item>
                        </Menu>
                      } trigger={['click']}
                        placement="bottomRight">
                        <Button type="primary" >Download</Button>
                      </Dropdown>
                      
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{marginTop: '30px'}} className="word-break-all">
               <Row gutter={16}>
                 <Col span={8} className="padding-0-3">
                   {
                     stWalletMovement &&
                     <StWalletMovementIc 
                      year={choosedYear!==null ? choosedYear : maxYear}
                      month={choosedMonth!==null ? choosedMonth : defaultMonth}
                      isChoosedYear={isChoosedYear}
                      history={history}
                      stWalletMovement={stWalletMovement}
                      toThousands={this.props.toThousands}
                     />
                   }
                 </Col>
                 <Col span={8} className="padding-0-3">
                   {
                     teWalletMovement && 
                     <TeWalletMovementIc 
                      year={choosedYear!==null ? choosedYear : maxYear}
                      month={choosedMonth!==null ? choosedMonth : defaultMonth}
                      isChoosedYear={isChoosedYear}
                      history={history}
                      teWalletMovement={teWalletMovement}
                      toThousands={this.props.toThousands}
                     />
                   }
                 </Col>
                 <Col span={8} className="padding-0-3">
                   {
                     afWalletMovement && 
                     <AfWalletMovement 
                      afWalletMovement={afWalletMovement}
                      toThousands={this.props.toThousands}
                     />
                     }
                 </Col>
               </Row>
                 <Row gutter={16}>
                  <Col span={8} className="padding-0-3">
                    {
                      stWalletMovement && 
                        <StWalletMovementDe 
                          year={choosedYear!==null ? choosedYear : maxYear}
                          month={choosedMonth!==null ? choosedMonth : defaultMonth}
                          isChoosedYear={isChoosedYear}
                          history={history}
                          stWalletMovement={stWalletMovement}
                          toThousands={this.props.toThousands}
                        />
                    }
                  </Col>
                  <Col span={8} className="padding-0-3">
                    {
                      teWalletMovement && 
                      <TeWalletMovementDe 
                        year={choosedYear!==null ? choosedYear : maxYear}
                        month={choosedMonth!==null ? choosedMonth : defaultMonth}
                        isChoosedYear={isChoosedYear}
                        history={history}
                        teWalletMovement={teWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                  <Col span={8} className="padding-0-3">
                    {
                      afWalletMovement && 
                      <AfWalletMovementDe 
                        afWalletMovement={afWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8} className="padding-0-3">
                    {
                      stWalletMovement && 
                      <StWalletMovementCB 
                        stWalletMovement={stWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                  <Col span={8} className="padding-0-3">
                    {
                      teWalletMovement && 
                      <TeWalletMovementCB 
                        teWalletMovement={teWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                  <Col span={8} className="padding-0-3">
                    {
                      afWalletMovement && 
                      <AfWalletMovementCB 
                        afWalletMovement={afWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                </Row>
              </div>
              <Modal
                title="Download Pdf Modal"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                width='750px'
              >
              <div className="payment pdfTable" style={{lineHeight: '1.8',fontSize: '20px'}}>
               <Row gutter={16}>
                 <Col span={colWidth} >
                   {
                     stWalletMovement && !downloadSt &&
                     <StWalletMovementIc 
                      stWalletMovement={stWalletMovement}
                      toThousands={this.props.toThousands}
                     />
                   }
                 </Col>
                 <Col span={colWidth} >
                   {
                     teWalletMovement && !downloadTe &&
                     <TeWalletMovementIc 
                      teWalletMovement={teWalletMovement}
                      toThousands={this.props.toThousands}
                     />
                   }
                 </Col>
                 <Col span={colWidth} >
                   {
                     afWalletMovement && !downloadAf &&
                     <AfWalletMovement 
                      afWalletMovement={afWalletMovement}
                      toThousands={this.props.toThousands}
                     />
                     }
                 </Col>
               </Row>
                 <Row gutter={16}>
                  <Col span={colWidth}>
                    {
                      stWalletMovement && !downloadSt &&
                        <StWalletMovementDe 
                          stWalletMovement={stWalletMovement}
                          toThousands={this.props.toThousands}
                        />
                    }
                  </Col>
                  <Col span={colWidth}>
                    {
                      teWalletMovement && !downloadTe &&
                      <TeWalletMovementDe 
                        teWalletMovement={teWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                  <Col span={colWidth} >
                    {
                      afWalletMovement && !downloadAf &&
                      <AfWalletMovementDe 
                        afWalletMovement={afWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={colWidth} >
                    {
                      stWalletMovement && !downloadSt && 
                      <StWalletMovementCB 
                        stWalletMovement={stWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                  <Col span={colWidth}>
                    {
                      teWalletMovement && !downloadTe &&
                      <TeWalletMovementCB 
                        teWalletMovement={teWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                  <Col span={colWidth} >
                    {
                      afWalletMovement && !downloadAf && 
                      <AfWalletMovementCB 
                        afWalletMovement={afWalletMovement}
                        toThousands={this.props.toThousands}
                      />
                    }
                  </Col>
                </Row>
               
              </div>
              </Modal>
            </div>
         );
    }
}
 
export default CreditMovements;