import React, { Component } from 'react';
import { Form, Icon, Input, Button, notification } from 'antd';
import '../assets/login.css';
import api from './Api';
import Cookies from 'js-cookie';
class Login extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api({
                    url: '/login',
                    method: "POST",
                    data: values,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
                }
                ).then(
                    res => {
                        if (res.data.code === 200) {
                            Cookies.set('isLogin', '1', { expires: 1 });
                            localStorage.setItem('userInfo', res.data.data.user.user_name);
                            localStorage.setItem('afsToken', res.data.data.token);
                            this.props.history.push('/dashboard');
                            notification.info({
                                message: 'Login Success'
                            });
                        }

                    }
                ).catch(
                    err => {
                        notification.error({
                            message: 'Error',
                            description:
                                'Please confirm your username or password!',
                        });
                        if (err.response?.data.code === 401) {
                            localStorage.removeItem('userInfo');
                            localStorage.removeItem('afsToken');
                            Cookies.remove('isLogin');
                            this.props.history.push('/login');
                        }
                    }
                )
            } else {
                console.log(err);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="container">
                <div className="content">
                    <div className="title">AFS</div>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('user_name', {
                                rules: [{ required: true, message: 'Please input your username!' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Username"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('user_pwd', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" block className="login-form-button" >
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

export default Form.create()(Login);