import Dashboard from "../components/Dashboard/Dashboard";
import AllBalance from "../components/Report/Balance/AllBalance";
import CreditMovements from "../components/Report/CreditMovements/CreditMovements";
import ModifyPasswordForm from "../components/System/ModifyPassword";
import ItalkiWalletBalance from "../components/Report/ItalkiWalletBalance/ItalkiWalletBalance";
import ItalkiWalletCreditTrans from "../components/Transactions/ItalkiWalletCreditTrans";
import CreditPurchases from "../components/Transactions/CreditPurchases";
import TeacherTransfers from "../components/Transactions/TeacherTransfers";
import TeacherWithdrawals from "../components/Transactions/TeacherWithdrawals";
import AffiliateWithdrawals from "../components/Transactions/AffiliateWithdrawals";
import StudentPurchase from "../components/Report/StudentPurchase/StudentPurchase";
import CreditBudgetUsage from "../components/Report/CreditBudgetUsage/CreditBudgetUsage";
import ItalkiWalletCredit from "../components/Report/ItalkiWalletCredit";
import ItalkiWalletTransactions from "../components/Report/WalletTransactions/WalletTransactions";
import UserWalletTransactions from "../components/Report/UserTransactions/UserTransactions";
import SalesTax from "../components/Report/SalesTax";

const routes = [
  {
    path: "/",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/report/all-wallet-balances",
    component: AllBalance,
  },
  {
    path: "/report/credit-movements",
    component: CreditMovements,
  },
  {
    path: "/report/italki-wallet-balances",
    component: ItalkiWalletBalance,
  },
  {
    path: "/report/credit-budget-usage",
    component: CreditBudgetUsage,
  },
  {
    path: "/setting/modifypassword",
    component: ModifyPasswordForm,
  },
  {
    path: "/transactions/italki-wallet-credit-transactions",
    component: ItalkiWalletCreditTrans,
  },
  {
    path: "/report/student-purchases",
    component: StudentPurchase,
  },
  {
    path: "/transactions/credit-purchases",
    component: CreditPurchases,
  },
  {
    path: "/transactions/teacher-transfers",
    component: TeacherTransfers,
  },
  {
    path: "/transactions/teacher-withdrawals",
    component: TeacherWithdrawals,
  },
  {
    path: "/transactions/affiliate-withdrawals",
    component: AffiliateWithdrawals,
  },
  {
    path: "/report/italki-wallet-credit",
    component: ItalkiWalletCredit,
  },
  {
    path: "/report/italki-wallet-transactions",
    component: ItalkiWalletTransactions,
  },
  {
    path: "/report/user-transaction-type",
    component: UserWalletTransactions,
  },
  {
    path: "/report/sales-tax",
    component: SalesTax,
  },
];

export default routes;
