import React, { Component } from 'react';
import '../../Balance/Balance.css';
import moment from 'moment';

class TeWalletMovementIc extends Component {

    linkToTrans = (type, itc) => {
        let month = this.props.month < 10 ? '0' + this.props.month.toString() : this.props.month.toString();
        if (itc > 0) {
            if (type === 'withdrawal') {
                this.props.history.push({
                    pathname: "/transactions/teacher-withdrawals", state: {
                        startYmd: this.props.isChoosedYear
                            ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD')
                            : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'),
                        endYmd: this.props.isChoosedYear
                            ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD')
                            : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), withdrawalStatus: 1
                    }
                });
            } else if (type === 'transfer same user') {
                this.props.history.push({ pathname: "/transactions/teacher-transfers", state: { startYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'), endYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), transferType: 1, sameUser: 1 } });
            } else if (type === 'transfer diff user') {
                this.props.history.push({ pathname: "/transactions/teacher-transfers", state: { startYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'), endYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), transferType: 1, sameUser: 2 } });
            }
        }
        console.log('month', this.props.month.toString(), this.props)

    }

    render() {
        return (
            <div>
                <table width='100%'>
                    <tbody>
                        <tr>
                            <th colSpan='2' className="top-title">Teacher Wallets</th>
                        </tr>
                        <tr>
                            <td className="text-bold bg-ddd">Opening Balance</td>
                            <td className="text-bold bg-ddd text-right">${this.props.toThousands(this.props.teWalletMovement.opening_balance)}</td>
                        </tr>
                        <tr>
                            <td className="text-bold bg-lightddd">Increase</td>
                            <td className="text-bold bg-lightddd text-right">${this.props.toThousands(this.props.teWalletMovement.increase)}</td>
                        </tr>
                        <tr>
                            <td className="text-500">Withdrawal Reversed(Cash)</td>
                            <td className={`text-500 text-right ${this.props.teWalletMovement.withdraw_cash_refund > 0 ? 'canClick' : ''}`} onClick={() => this.linkToTrans('withdrawal', this.props.teWalletMovement.withdraw_cash_refund)}>${this.props.toThousands(this.props.teWalletMovement.withdraw_cash_refund)}</td>
                        </tr>
                        <tr>
                            <td className="text-500">From italki Wallets</td>
                            <td className="text-500 text-right">${this.props.toThousands(this.props.teWalletMovement.from_sub_wallet.total_itc)}</td>
                        </tr>
                        {
                            this.props.teWalletMovement.from_sub_wallet.account_dic && Object.keys(this.props.teWalletMovement.from_sub_wallet.account_dic).map((item, i) => {
                                return (
                                    <tr key={i + 'twfs'} className="color-light">
                                        <td>- {item}</td>
                                        <td className="text-right">${this.props.toThousands(this.props.teWalletMovement.from_sub_wallet.account_dic[item])}</td>
                                    </tr>)
                            })
                        }
                        <tr>
                            <td className="text-500">From Student Wallets</td>
                            <td className="text-500 text-right">${this.props.toThousands(this.props.teWalletMovement.from_student + this.props.teWalletMovement.from_diff_user)}</td>
                        </tr>
                        <tr className="color-light">
                            <td>- Same User Transfer</td>
                            <td className={`text-right ${this.props.teWalletMovement.from_student > 0 ? 'canClick' : ''}`} onClick={() => this.linkToTrans('transfer same user', this.props.teWalletMovement.from_student)}>${this.props.toThousands(this.props.teWalletMovement.from_student)}</td>
                        </tr>
                        <tr className="color-light">
                            <td>- Different User Transfer</td>
                            <td className={`text-right ${this.props.teWalletMovement.from_diff_user > 0 ? 'canClick' : ''}`} onClick={() => this.linkToTrans('transfer diff user', this.props.teWalletMovement.from_diff_user)}>${this.props.toThousands(this.props.teWalletMovement.from_diff_user)}</td>
                        </tr>
                        <tr>
                            <td className="text-500">From Affiliate Wallets</td>
                            <td className="text-500 text-right">${this.props.toThousands(this.props.teWalletMovement.from_affiliate)}</td>
                        </tr>
                    </tbody>

                </table>
            </div>)
    }

}
export default TeWalletMovementIc;