import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Row, Col, Select, Button, Spin } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import api from '../../Api';
import './StudentPurchase.css';

const { MonthPicker } = DatePicker;
const { Option } = Select;

interface StuPurData {
    [propNames: string]: any;
    [propNames: number]: any;
}
// interface TypeObj {
//     [propNames: string]: any;
//     [propNames: number]: any;
// };

interface PostParams {
    start_ym: number | string;
    end_ym: number | string;
    supplier_id?: number;
    page?: number;
    page_size?: number;
    get_total?: number;
    total?: number;
    download_csv?: number;
    y_or_m: string;
}

interface PropsInfo {
    [propNames: string]: any;
}

// type PageObj = {
//     page_size: number;
//     page: number;
// }

const StudentPurchase: React.FC<{}> = (props: PropsInfo) => {
    const [start_ym, setStart_ym] = useState<any>(props.location.state ? moment(props.location.state.startYmd) : moment().month(moment().month() - 1).startOf('month'));
    const [end_ym, setEnd_ym] = useState<any>(props.location.state ? moment(props.location.state.endYmd) : moment().month(moment().month() - 1).endOf('month'));
    const [loading, setLoading] = useState<boolean>(false);
    const [stuPurData, setStuPurData] = useState<StuPurData[]>([]);
    // const [totalItc, setTotalItc] = useState<number>();
    // const [pageObj, setPageObj] = useState<PageObj>({ page_size: 50, page: 1 })
    const [dataTotal, setDataTotal] = useState<number>()
    const [isChoosedYear, setIsChoosedYear] = useState<string>('');
    const [startYearArr, setStartYearArr] = useState<any>([]);
    const [endYearArr, setEndYearArr] = useState<any>([]);
    const [startValue, setStartValue] = useState<any>(moment().subtract(6, 'months'));
    const [endValue, setEndValue] = useState<any>(moment().subtract(1, 'months'));
    const [startOpen, setStartOpen] = useState<any>();
    const [endOpen, setEndOpen] = useState<any>();
    const [dateTitle, setDateTitle] = useState<any>([]);

    // const paginationProps = {
    //     showSizeChanger: false,
    //     showQuickJumper: false,
    //     showTotal: () => `Total: $ ${props.toThousands(totalItc)}, 共${dataTotal}条`,
    //     pageSize: pageObj && pageObj.page_size,
    //     current: pageObj && pageObj.page,
    //     total: dataTotal,
    //     onChange: (current: number) => changePage(current),
    // }

    function getData(page: number, type?: string) {
        setLoading(true);
        let paramStartDate: number = !!isChoosedYear && isChoosedYear === 'Year' && (start_ym || startValue) ? start_ym : startValue.format('YYYY-MM').replace(/-/g, '');
        let paramEndDate: number = !!isChoosedYear && isChoosedYear === 'Year' && (start_ym || startValue) ? end_ym : endValue.format('YYYY-MM').replace(/-/g, '');
        let params: PostParams = {
            start_ym: paramStartDate,
            end_ym: paramEndDate,
            y_or_m: isChoosedYear === 'Year' ? 'Y' : 'M',
            // page: page,
            // page_size: 50,
            // get_total: 1
        }
        if (dataTotal && type !== 'searchData') {
            params['get_total'] = 0;
            params['total'] = dataTotal;
        }
        api.get('/report/student-purchases', { params }).then(
            res => {
                setLoading(false);
                let resData = res.data;
                let purchaseDetailArr = resData.data.purchase_itc_detail;
                let refundDetailArr = resData.data.refund_itc_detail;
                let dataResourceArr = [];
                dataResourceArr.push({ 'title': 'PURCHASE', 'key': 'Purchase' });
                function typeObject(title: string, key: string, data?: any, currency?: string) {
                    let dateArr = resData.data.time_str_s;
                    let finalObj = { 'title': title, 'key': key };
                    finalObj['currency'] = currency;
                    if (data) {
                        dateArr.map((item: any, index: any) => {
                            finalObj[item] = props.toThousands(Number(data[index]));
                            return finalObj;
                        })
                    }
                    dataResourceArr.push(finalObj);
                }
                typeObject('ITALKI CREDITS', 'ITALKI_CREDITS', resData.data.purchase_itc_total, 'USD');
                for (let key in purchaseDetailArr) {
                    typeObject(`-- ${purchaseDetailArr[key].pay_supplier_name}`, `purchaseDetail${key}`, purchaseDetailArr[key].amount_arr, 'USD');
                }
                typeObject('REAL GROSS MONEY', 'REAL_GROSS_MONEY');
                for (let key in resData.data.pay_money_detail) {
                    let currency = key;
                    let paymentMethod = resData.data.pay_money_detail[key];
                    for (let key in paymentMethod) {
                        typeObject(`-- ${paymentMethod[key].pay_supplier_name}`, `pay_method${paymentMethod[key].pay_supplier_name}`, paymentMethod[key].amount_arr, currency);
                    }
                }
                typeObject('', 'refund');
                typeObject('REFUND - ITALKI CREDITS', 'refund_italki_credits', resData.data.refund_itc_total, 'USD')
                for (let key in refundDetailArr) {
                    typeObject(`-- ${refundDetailArr[key].pay_supplier_name}`, `refundDetail${key}`, refundDetailArr[key].amount_arr, 'USD');
                }
                setStuPurData(dataResourceArr);
                setDateTitle(resData.data.time_str_s);
                setDataTotal(resData.paging.total);
            }
        ).catch(
            err => {
                setLoading(false);
                if (err.response && err.response.data && err.response.data.code === 401) {
                    localStorage.removeItem('userInfo');
                    localStorage.removeItem('afsToken');
                    Cookies.remove('isLogin');
                    props.history.push('/login');
                }
            }
        )
    }

    useEffect(() => {
        let startYmd: string;
        let endYmd: string;
        let minyear: number = 2008;
        let maxYear: number = new Date().getFullYear();
        let yearArr: any = [];
        for (let ayear = minyear; ayear <= maxYear - 1; ayear++) {
            yearArr.unshift(ayear)
        }
        setStartYearArr(yearArr);
        function getDefaultData() {
            setLoading(true);
            let params: PostParams = {
                start_ym: startYmd ? startYmd.replace(/-/g, '') : moment().month(moment().month() - 6).startOf('month').format('YYYY-MM').replace(/-/g, ''),
                end_ym: endYmd ? endYmd.replace(/-/g, '') : moment().month(moment().month() - 1).endOf('month').format('YYYY-MM').replace(/-/g, ''),
                // page: 1,
                y_or_m: 'M',
                // page_size: 50,
                // get_total: 1
            }
            api.get('/report/student-purchases', { params }).then(
                res => {
                    setLoading(false);
                    let resData = res.data;
                    let purchaseDetailArr = resData.data.purchase_itc_detail;
                    let refundDetailArr = resData.data.refund_itc_detail;
                    let dataResourceArr = [];
                    dataResourceArr.push({ 'title': 'PURCHASE', 'key': 'Purchase' });
                    function typeObject(title: string, key: string, data?: any, currency?: string) {
                        let dateArr = resData.data.time_str_s;
                        let finalObj = { 'title': title, 'key': key };
                        finalObj['currency'] = currency;
                        if (data) {
                            dateArr.map((item: any, index: any) => {
                                finalObj[item] = props.toThousands(Number(data[index]));
                                return finalObj;
                            })
                        }
                        dataResourceArr.push(finalObj);
                    }
                    typeObject('ITALKI CREDITS', 'ITALKI_CREDITS', resData.data.purchase_itc_total, 'USD');
                    for (let key in purchaseDetailArr) {
                        typeObject(`-- ${purchaseDetailArr[key].pay_supplier_name}`, `purchaseDetail${key}`, purchaseDetailArr[key].amount_arr, 'USD');
                    }
                    typeObject('REAL GROSS MONEY', 'REAL_GROSS_MONEY');
                    for (let key in resData.data.pay_money_detail) {
                        let currency = key;
                        let paymentMethod = resData.data.pay_money_detail[key];
                        for (let key in paymentMethod) {
                            typeObject(`-- ${paymentMethod[key].pay_supplier_name}`, `pay_method${paymentMethod[key].pay_supplier_name}`, paymentMethod[key].amount_arr, currency);
                        }
                    }
                    typeObject('', 'refund');
                    typeObject('REFUND - ITALKI CREDITS', 'refund_italki_credits', resData.data.refund_itc_total, 'USD')
                    for (let key in refundDetailArr) {
                        typeObject(`-- ${refundDetailArr[key].pay_supplier_name}`, `refundDetail${key}`, refundDetailArr[key].amount_arr, 'USD');
                    }
                    setStuPurData(dataResourceArr);
                    setDateTitle(resData.data.time_str_s);
                    setDataTotal(resData.paging.total);
                }
            ).catch(
                err => {
                    setLoading(false);
                    if (err.response && err.response.data.code === 401) {
                        localStorage.removeItem('userInfo');
                        localStorage.removeItem('afsToken');
                        Cookies.remove('isLogin');
                        props.history.push('/login');
                    }
                }
            )
        }
        getDefaultData()
    }, [props])
    function handleOpenChange(e: any, type: number) {
        if (type === 0) {
            setStartOpen(e)
        } else {
            setEndOpen(e)
        }
    }
    function disabledStartDate(e: any) {
        if (!e || !endValue) {
            return e <= moment(new Date('2008/11/01')) || e.valueOf() > moment().month(moment().month() - 1).endOf('month');
        }
        return e <= moment(new Date('2008/11/01')) || e.valueOf() > endValue.valueOf();
    };
    function disabledEndDate(e: any) {
        if (!e || !startValue) {
            return false;
        }
        return e.valueOf() <= startValue.valueOf() || e.valueOf() > moment().month(moment().month() - 1).endOf('month');
    };
    function getYearData(maxYear: number) {
        let yearArr = [];
        let currentYear = new Date().getFullYear();
        for (let ayear = maxYear; ayear <= currentYear; ayear++) {
            yearArr.unshift(ayear)
        }
        setEndYearArr(yearArr);
        setStart_ym(maxYear);
        if (maxYear > end_ym) {
            setEnd_ym(maxYear);
        }
    }
    function getEndYearData(endYear: number) {
        setEnd_ym(endYear);
    }
    function changeMonth(e: any, type: number) {
        if (type === 0) {
            setStartValue(e);
        } else {
            setEndValue(e);
        }
    }
    function searchData() {
        getData(1, 'searchData');
    }

    function downloadAsCSV() {
        let params: any = {};
        if (isChoosedYear) {
            params['y_or_m'] = 'Y';
            params['start_ym'] = start_ym;
            params['end_ym'] = end_ym;
        } else {
            params['y_or_m'] = 'M';
            params['start_ym'] = moment(startValue).format('YYYYMM');
            params['end_ym'] = moment(endValue).format('YYYYMM');
        }
        let url = `${process.env.REACT_APP_BASE_URL}/report/student-purchases?y_or_m=${params['y_or_m']}&start_ym=${params['start_ym']}&end_ym=${params['end_ym']}&download_csv=1&token=${localStorage.getItem('afsToken')}`
        window.open(url);
    }
    console.log('props', props)
    return (
        <div>
            <Spin size="large" spinning={loading} className="loading" />
            <div>
                <Row gutter={24}>
                    <Col span={18} className="padding-0-5-0-15">
                        <label>View By</label>
                        <Row style={{ 'paddingTop': '10px' }}>
                            <Col span={6}>
                                <Select defaultValue={isChoosedYear ? 'Year' : 'Month'} style={{ width: '100%' }} onChange={(e: any) => setIsChoosedYear(e)}>
                                    {/* <Option value='Year'>Year</Option> */}
                                    <Option value='Month'>Month</Option>
                                </Select>
                            </Col>
                            <Col span={1}></Col>
                            {
                                !!isChoosedYear && isChoosedYear === 'Year' ? (
                                    <Col span={12} >
                                        <Row gutter={24}>
                                            <Col span={10} className="padding-0 margin-0">
                                                <Select style={{ width: '100%' }} placeholder='Choose StartYear' onChange={(e: number) => getYearData(e)}>
                                                    {
                                                        startYearArr.length > 0 && startYearArr.map((y: any, index: number) => {
                                                            return <Option value={y} key={index + 'startyear'}>{y}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                            <Col span={1} className='button-padding margin-0'>
                                                <Button className="button-style" disabled={true}>~</Button>
                                            </Col>
                                            <Col span={10} className="padding-0 margin-0">
                                                <Select style={{ width: '100%' }} placeholder='Choose EndYear' onChange={(e: number) => getEndYearData(e)}>
                                                    {
                                                        endYearArr.length > 0 && endYearArr.map((y: any, index: number) => {
                                                            return <Option value={y} key={index + 'endyear'}>{y}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Col>
                                            <Col span={3}>
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : (
                                        <Col span={12} >
                                            <Row gutter={24}>
                                                <Col span={7} className="padding-0 margin-0">
                                                    <MonthPicker
                                                        disabledDate={(e) => disabledStartDate(e)}
                                                        format="YYYY/MM"
                                                        value={startValue}
                                                        placeholder="Start"
                                                        onChange={(e) => changeMonth(e, 0)}
                                                        open={startOpen}
                                                        onOpenChange={(e) => handleOpenChange(e, 0)}
                                                    />
                                                </Col>
                                                <Col span={1} className='button-padding margin-0'>
                                                    <Button className="button-style" disabled={true}>~</Button>
                                                </Col>
                                                <Col span={7} className="padding-0 margin-0">
                                                    <MonthPicker
                                                        disabledDate={(e) => disabledEndDate(e)}
                                                        disabled={!startValue}
                                                        format="YYYY/MM"
                                                        value={endValue}
                                                        placeholder="End"
                                                        onChange={(e) => changeMonth(e, 1)}
                                                        open={endOpen}
                                                        onOpenChange={(e) => handleOpenChange(e, 1)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                            }
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Button type="primary" disabled={!start_ym || !end_ym} className='float-right viewButtonStyle' onClick={searchData}>View</Button>
                        <Button type="primary" disabled={!start_ym || !end_ym} className='float-right downloadCsvStyle' onClick={() => downloadAsCSV()}>Download as CSV</Button>

                    </Col>
                </Row>
            </div>
            <Table<StuPurData> dataSource={stuPurData} pagination={false}>
                <Table.Column<StuPurData> key={`title`} title="PAYMENT SUPPLIER" width={'18%'} render={(StuPurData) => (
                    ['PURCHASE', 'REFUND - ITALKI CREDITS', 'REAL GROSS MONEY', 'ITALKI CREDITS'].indexOf(StuPurData.title) !== -1 ? <div style={{ 'fontWeight': 'bold' }}>
                        {StuPurData.title}
                    </div> : <div>
                            {StuPurData.title}
                        </div>
                )} />
                <Table.Column<StuPurData> key="order_id" title="CURRENCY" width={'12%'} render={(StuPurData) => (
                    <div className={['ITALKI CREDITS','REFUND - ITALKI CREDITS'].indexOf(StuPurData.title) !== -1 ? 'font-bold' : ''}>{StuPurData.currency}</div>
                )} />
                {
                    dateTitle && dateTitle.length> 0 && dateTitle.map((date: any) => (
                        <Table.Column<StuPurData> key={`dateTitle${date}`} title={date} render={(StuPurData) => (
                            <div className={['ITALKI CREDITS','REFUND - ITALKI CREDITS'].indexOf(StuPurData.title) !== -1 ? 'font-bold' : ''}>{StuPurData[date]}</div>
                        )}/>
                    ))
                }
                {/* <Table.Column<StuPurData> key="usd" title="AMOUNT" dataIndex="usd" />
                <Table.Column<StuPurData> key="user_id" title="USER ID" dataIndex="user_id" />
                <Table.Column<StuPurData> key="pay_supplier_name" title="PAYMENT METHOD" dataIndex="pay_supplier_name" />
                <Table.Column<StuPurData> key="status" title="STATUS"/> */}
            </Table>
        </div>
    )
}

export default StudentPurchase;