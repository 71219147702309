import _base from "./_base";

const getConfigBase = _base
export default getConfigBase

export const getProviderConfig = () => getConfigBase('PAYMENT_SUPPLIER', data => Array.from(Object.keys(data), item => ({ 'label': data[item], 'value': Number(item) })))

export const getPaymentMethodConfig = () => getConfigBase('PAYMENT_TYPE', data => Array.from(Object.keys(data), item => ({ 'label': data[item].name, 'value': Number(item) })))

export const getSubAccountsConfig = () => getConfigBase('SUB_ACCOUNTS', data => Object.values(data).map(item => ({ value: item.id, label: item.name })))

export const getTrannsactionTypeConfig = () => getConfigBase('TRANSACTION_SHOW_TYPE', data => Object.values(data).filter(item => item.account_id !== '0').map(item => ({ label: item.name, value: item.show_type, account_id: item.account_id })))
