import React, { Component } from 'react';
import { Layout, Menu, Icon, Breadcrumb, Button } from 'antd';
import { Route, Link, withRouter } from "react-router-dom";
import routes from ".././Router/Router";
import Cookies from 'js-cookie';
import '../App.css';
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const breadcrumbNameMap = {
  '/dashboard': 'Dashboard',
  '/report': 'Report',
  '/report/all-wallet-balances': 'All Wallet Balances',
  '/report/credit-movements': 'User Credit Movements',
  '/report/italki-wallet-balances': 'Italki Wallet Balance',
  '/report/student-purchases': 'Student Purchases',
  '/report/credit-budget-usage': 'Credit Budget Usage',
  '/report/italki-wallet-transactions': 'Italki Wallet Transactions',
  '/report/user-transaction-type': 'User Wallet Transactions',
  '/report/sales-tax': 'Sales Tax',
  '/setting': 'Setting',
  '/setting/modifypassword': 'ChangePassword',
  '/transactions': 'Transactions',
  '/transactions/italki-wallet-credit-transactions': 'Wallet Credit Transactions',
  '/transactions/credit-purchases': 'Credit Purchases',
  '/transactions/teacher-transfers': 'Teacher Transfers',
  '/transactions/teacher-withdrawals': 'Teacher Withdrawals',
  '/transactions/affiliate-withdrawals': 'Affiliate Withdrawals',
  "/report/italki-wallet-credit": "Italki Credit Movements"
};

/* 面包屑导航 */
const BreadCrumbMenu = withRouter(props => {
  const { location } = props;
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <Breadcrumb>{breadcrumbItems}</Breadcrumb>
  );
});

/* 侧边栏导航 */
const SiderMenu = withRouter(props => {
  const { location } = props;
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} selectedKeys={[location.pathname]}>
      <Menu.Item key="/">
        <Link to="/">
          <Icon type="user" />
          <span>Dashboard</span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="sub1"
        title={
          <span>
            <Icon type="container" />
            <span>Reports</span>
          </span>
        }>
        <Menu.Item key="/report/all-wallet-balances">
          <Link to="/report/all-wallet-balances">
            All Wallet Balances
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/italki-wallet-balances">
          <Link to="/report/italki-wallet-balances">
            Italki Wallet Balances
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/credit-movements">
          <Link to="/report/credit-movements">
            User Credit Movements
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/italki-credit">
          <Link to="/report/italki-wallet-credit">
            Italki Credit Movements
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/student-purchases">
          <Link to="/report/student-purchases">
            Student Purchases
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/credit-budget-usage">
          <Link to="/report/credit-budget-usage">
            Credit Budget Usage
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/italki-wallet-transactions">
          <Link to="/report/italki-wallet-transactions">
            Italki Wallet Transactions
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/user-transaction-type">
          <Link to="/report/user-transaction-type">
            User Wallet Transactions
          </Link>
        </Menu.Item>
        <Menu.Item key="/report/sales-tax">
          <Link to="/report/sales-tax">
            Sales Tax
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="sub2"
        title={
          <span>
            <Icon type="container" />
            <span>Transactions</span>
          </span>
        }>
        <Menu.Item key="/transactions/italki-wallet-credit-transactions">
          <Link to="/transactions/italki-wallet-credit-transactions">
            Wallet Credit Transactions
          </Link>
        </Menu.Item>
        <Menu.Item key="/transactions/credit-purchases">
          <Link to="/transactions/credit-purchases">
            Credit Purchases
          </Link>
        </Menu.Item>
        <Menu.Item key="/transactions/teacher-transfers">
          <Link to="/transactions/teacher-transfers">
            Teacher Transfers
          </Link>
        </Menu.Item>
        <Menu.Item key="/transactions/teacher-withdrawals">
          <Link to="/transactions/teacher-withdrawals">
            Teacher Withdrawals
          </Link>
        </Menu.Item>
        <Menu.Item key="/transactions/affiliate-withdrawals">
          <Link to="/transactions/affiliate-withdrawals">
            Affiliate Withdrawals
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="sub7"
        title={
          <span>
            <Icon type="setting" />
            <span>Settings</span>
          </span>
        }>
        <Menu.Item key="/setting/modifypassword">
          <Link to="/setting/modifypassword">
            Change Password
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
})

class LayoutContent extends Component {
  state = {
    collapsed: false,
    user: localStorage.getItem('userInfo')
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('afsToken');
    Cookies.remove('isLogin');
    this.props.history.push('/login');
  }
  toThousands = (num) => {
    let formatNum = (num / 100).toFixed(2);
    let dotNum = formatNum.slice(formatNum.length - 3);
    let newNum = formatNum.substring(0, formatNum.length - 3) || 0;
    return newNum.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + dotNum;
  }
  render() {
    return (
      <Layout>
        <Sider width={250} trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo" style={{ background: 'transparent', color: 'rgba(255, 255, 255, 0.65)', fontSize: '24px', paddingLeft: '10px' }}>AFS</div>
          <SiderMenu />
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Icon
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
            <div style={{ float: 'right', marginRight: '20px' }}>
              <span style={{ marginRight: '5px' }}>{this.state.user}</span>
              <Button onClick={this.logout}><Icon type="logout" /></Button>
            </div>

          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            <BreadCrumbMenu />
            {
              routes.map((route, key) => {
                if (route.exact) {
                  return <Route exact key={key} path={route.path}
                    render={props => (
                      <route.component {...props} routes={route.routes} />
                    )}
                  />
                }
                return <Route key={key} path={route.path}
                  render={props => (
                    <route.component {...props} routes={route.routes} toThousands={this.toThousands} />
                  )}
                />
              })
            }
          </Content>
        </Layout>
      </Layout>
    )
  }
}
export default LayoutContent;
