import React from 'react';
import '../../Balance/Balance.css';

const TeWalletMovementCB = props =>{
    return (
        <div>
            <table width='100%'>
            <tbody>
            <tr>
                <td className="text-bold bg-ddd">Closing Balance<br/>(OB+Incr-Decr)</td>
                <td className="text-bold bg-ddd text-right" style={{color: props.teWalletMovement.closing_balance_figure_up !== props.teWalletMovement.closing_balance ? 'red' : ''}}>${props.toThousands(props.teWalletMovement.closing_balance_figure_up)}</td>
            </tr>
            <tr>
                <td className="text-bold bg-ddd">Closing Balance<br/>(Snapshot)</td>
                <td className="text-bold bg-ddd text-right">${props.toThousands(props.teWalletMovement.closing_balance)}</td>
            </tr>
            </tbody>  
            </table>
        </div>
    )
}

export default TeWalletMovementCB;