import React, { Component } from 'react';
import { DatePicker,Row, Col, Select, Button, Spin, Dropdown, Menu, notification, Tooltip  } from 'antd';
import moment from 'moment';
import Cookies from 'js-cookie';
import api from '../../Api';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const { Option } = Select;
const { MonthPicker } = DatePicker;

class ItalkiWalletBalance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startValue: moment().subtract(6,'months'),
            endValue: moment().subtract(1,'months'),
            startYear: null,
            endYear: null,
            startopen: false,
            endOpen: false,
            loading: false,
            timeArr: [],
            italkiWalletBalaceDetail:[],
            isChoosedYear: false,
            minYear:2008,
            maxYear:new Date().getFullYear(),
            startYearArr: [],
            endYearArr: [],
        }
    }

    componentDidMount(){
      const { minYear } = this.state;
      this.getData();
      this.getYearData(minYear);
    }

    getData = (item) =>{
      const { startValue, endValue } = this.state;
      let params;
      if(item){
        params = item
      }else{
        params = {start_ym: startValue.format('YYYYMM'),end_ym: endValue.format('YYYYMM'),y_or_m: 'M'};
      }
      api.get('/report/sub-wallet-balance',{params}).then(
        res => {
          let italkiWalletBalance = res.data.data;
          let oldItalkiWalletBalace = italkiWalletBalance.wallet_increase_decrease_dic;
          let itWaBArr = [];
          let newItWaObjArr = [];
          Object.keys(oldItalkiWalletBalace).forEach((item) =>{
            itWaBArr.push(item)
          })

          itWaBArr = itWaBArr.sort((a,b)=>{
            return a.replace(/[^0-9]/ig,"") - b.replace(/[^0-9]/ig,"")
          })
          itWaBArr.forEach((i,index,arr)=>{
            for (var key in oldItalkiWalletBalace){

              if(key.replace(/[^0-9]/ig,"") === i){
                arr[index] = key
              }
            }
          })

          itWaBArr.forEach((v)=>{
            newItWaObjArr.push({[v]:oldItalkiWalletBalace[v]})
          })

          let itWaOutPut = {}
          for( let i=0;i<newItWaObjArr.length; i++){
            itWaOutPut[Object.keys(newItWaObjArr[i])[0]] = newItWaObjArr[i][Object.keys(newItWaObjArr[i])[0]]
          }
          let newItalkiWalletBalace = [];
          this.setState({
            timeArr: italkiWalletBalance['time_str_s'],
            loading: false
          })
          for (var key in itWaOutPut){
            newItalkiWalletBalace[key] = itWaOutPut[key];
            this.setState({
              italkiWalletBalaceDetail: newItalkiWalletBalace
            })
          }
        }
      ).catch(
        err => {
          this.setState({
            loading: false
          })
          notification.error({
            message: 'Error',
            description: err.response?.data.msg
          });
          if(err.response?.data.code === 401){
            localStorage.removeItem('userInfo');
            localStorage.removeItem('afsToken');
            Cookies.remove('isLogin');
            this.props.history.push('/login');
          }
        }
      )
    }

    getYearData = (minyear) =>{
      const { maxYear } = this.state;
      let yearArr = [],endYearArr = [];
      for (let ayear = minyear; ayear <= maxYear-1; ayear++){
        yearArr.unshift(ayear)
      }
      endYearArr.forEach((y)=>{
        if(y>=maxYear){
          endYearArr.unshift(y);
        }
      })
      this.setState({
        startYearArr: yearArr,
        endYearArr: endYearArr
      })
    }

      disabledStartDate = startValue => {
        const { endValue } = this.state;
        if (!startValue || !endValue) {
          return startValue <= moment(new Date('2008/11/01')) || startValue.valueOf() > moment().month(moment().month()-1).endOf('month');
        }
        return startValue <= moment(new Date('2008/11/01')) || startValue.valueOf() > endValue.valueOf();
      };
    
      disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
          return false;
        }
        return endValue.valueOf() <= startValue.valueOf() || endValue.valueOf() > moment().month(moment().month()-1).endOf('month');
      };

      onChange = (field, value) => {
        this.setState({
          [field]: value,
        });
      };
    
      onStartChange = value => {
        this.onChange('startValue', value);
      };
    
      onEndChange = value => {
        this.onChange('endValue', value);
      };
    
      handleStartOpenChange = open => {
        this.setState({ startopen: open });
      };
    
      handleEndOpenChange = open => {
        this.setState({ endOpen: open });
      };

      balanceTitle = (title) => {
        switch (title){
          case 'sv_itc_s':
            return <span style={{fontWeight:'bold'}}>Student Wallets</span>
          case 'tv_itc_s':
            return <span style={{fontWeight:'bold'}}>Teacher Wallets</span>
          case 'av_itc_s':
            return <span style={{fontWeight:'bold'}}>Affiliate Wallets</span>
          case 'isub_wallet_itc_s':
            return <span style={{fontWeight:'bold'}}>italki Wallets</span>
          default:
            return <span style={{marginLeft: '5px'}}>{title}</span>;
        }
          
      }

      chooseYearOrMonth = (value) =>{
        if(value === 'Month'){
          this.setState({
            isChoosedYear: false,
            startYear: null,
            endYear: null
          })
        }else{
          this.setState({
            isChoosedYear: true,
            startValue: null,
            endValue: null
          })
        }
      }

      handleYear = (value) =>{
        const {startYearArr} = this.state;
        let newEndYearArr = [];
        startYearArr.forEach((endY)=>{
          if(endY>=value){
            newEndYearArr.push(endY);
          }
        })
        this.setState({
          endYearArr: newEndYearArr,
          startYear: value
        })
      }

      handleEndYear = (value)=>{
        this.setState({
          endYear: value
        })
      }

      linkToTrans = (item,type,arrIndex,itc) =>{
        let walletId = item.replace(/[^0-9]/ig,"");
        if((type === 'increase' && itc.increase_itc>0) || (type === 'decrease' && itc.decrease_itc>0)){
          this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: walletId , date: this.state.timeArr[arrIndex], inout: type==='increase' ? 1:-1, relate_wallet_type: walletId === '106' ? (item ==='(106)S Credits Expiry Account' ? '0' : '1') : ''}});
        }
      }

      checkData = () =>{
        const { startValue, endValue, startYear, endYear, isChoosedYear} = this.state;
        if(isChoosedYear){
          if(startYear != null && endYear != null){
            return false;
          }
        }else{
          if(startValue != null && endValue != null){
            return false;
          }
        }
        return true;
      }

      searchData = () =>{
        const { startValue, endValue, startYear, endYear, isChoosedYear} = this.state;
        this.setState({
          loading: true
        })
        let params = {};
        if(isChoosedYear){
          params['y_or_m'] = 'Y';
          params['start_ym'] = startYear;
          params['end_ym'] = endYear;
        }else{
          params['y_or_m'] = 'M';
          params['start_ym'] = moment(startValue).format('YYYYMM');
          params['end_ym'] = moment(endValue).format('YYYYMM');
        }
        this.getData(params);
      }

      downloadAsCSV = () =>{
        const { startValue, endValue, startYear, endYear, isChoosedYear} = this.state;
        let params = {};
        if(isChoosedYear){
          params['y_or_m'] = 'Y';
          params['start_ym'] = startYear;
          params['end_ym'] = endYear;
        }else{
          params['y_or_m'] = 'M';
          params['start_ym'] = moment(startValue).format('YYYYMM');
          params['end_ym'] = moment(endValue).format('YYYYMM');
        }
        let url = `${process.env.REACT_APP_BASE_URL}/report/sub-wallet-balance?y_or_m=${params['y_or_m']}&start_ym=${params['start_ym']}&end_ym=${params['end_ym']}&download_csv=1&token=${localStorage.getItem('afsToken')}`
        window.open(url);
      }

      downloadAsPDF = () =>{
        let target = document.getElementsByClassName("pdfTable")[0];
        html2canvas(target,{scale: 2}).then(function(canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 575.28;
        let imgHeight = (592.28/contentWidth * contentHeight)-20;
        let pageData = canvas.toDataURL('image/jpeg', 1.0);
        let pdf = new jsPDF('', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 10, 10, imgWidth, imgHeight );
        } else {
            while(leftHeight > 0) {
                pdf.addImage(pageData, 'JPEG', 10, position+10, imgWidth, imgHeight)
                leftHeight -= pageHeight;
                position -= 841.89;
                if(leftHeight > 0) {
                  pdf.addPage();
                }
            }
        }
        pdf.save("Italki wallet balance.pdf");
    })
    }

   render(){
    const { timeArr, italkiWalletBalaceDetail, loading, isChoosedYear, startYearArr, endYearArr, startValue, endValue, startopen, endOpen } = this.state;
       return(
           <div style={{width: '100%'}} className="payment">
             <Spin size="large" spinning={loading} className="loading"/>
              <Row gutter={16}>
                <Col span={6}>
                  <p>View By</p>
                  <Select defaultValue={isChoosedYear?'Year':'Month'} style={{width: '100%'}} onChange={this.chooseYearOrMonth}>
                    {/* <Option value='Year'>Year</Option> */}
                    <Option value='Month'>Month</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <div>
                    {
                      isChoosedYear ? (
                      <div>
                          <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{width: '46%'}}>
                              <p>From</p>
                              <Select style={{width: '100%'}} onChange={this.handleYear} placeholder='Choose'>
                                {
                                  startYearArr.length>0 && startYearArr.map((y,index)=>{
                                    return <Option value={y} key={index+'itstartyear'}>{y}</Option>
                                  })
                                }
                              </Select>
                            </div>
                            <div><p>&nbsp;</p>&nbsp;-&nbsp;</div>
                            <div style={{width: '46%'}}>
                              <p>To</p>
                              <Select style={{width: '100%'}} onChange={this.handleEndYear} placeholder='Choose'>
                                {
                                  endYearArr.length>0 && endYearArr.map((y,index)=>{
                                    return <Option value={y} key={index+'itendyear'}>{y}</Option>
                                  })
                                }
                              </Select>
                            </div>
                            
                        </div>
                      </div>
                      ) : (
                      <div>
                        
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                          <div>
                            <p>From</p>
                            <MonthPicker 
                              disabledDate={this.disabledStartDate}
                              // showTime
                              format="YYYY/MM"
                              value={startValue}
                              placeholder="Start"
                              onChange={this.onStartChange}
                              open={startopen}
                              onOpenChange={this.handleStartOpenChange}
                            />
                          </div>
                        <div><p>&nbsp;</p>&nbsp;-&nbsp;</div>
                        <div>
                          <p>To</p>
                          <MonthPicker 
                              disabledDate={this.disabledEndDate}
                              disabled={!startValue}
                              // showTime
                              format="YYYY/MM"
                              value={endValue}
                              placeholder="End"
                              onChange={this.onEndChange}
                              open={endOpen}
                              onOpenChange={this.handleEndOpenChange}
                          />
                        </div>
                        
                        </div>
                      </div>
                      )
                    }
                    
                    
                  </div>
                </Col>
                <Col span={6}>
                  <p>&nbsp;</p>
                  <div style={{float: 'right'}}>
                    <Button disabled={this.checkData()} type="primary" style={{marginRight: '5px'}} onClick={this.searchData}>View</Button>
                    <Dropdown overlay={
                        <Menu>
                          <Menu.Item key="itdowloadbalance0">
                            <span onClick={this.downloadAsCSV}>Download as CSV</span>
                          </Menu.Item>
                          <Menu.Item key="itdowloadbalance1">
                            <Tooltip title="It is recommended to download PDF only for 6 months or 6 years">
                              <span onClick={this.downloadAsPDF}>Download as PDF</span>
                            </Tooltip>
                          </Menu.Item>
                        </Menu>
                      } trigger={['click']}
                        placement="bottomRight">
                        <Button type="primary" >Download</Button>
                      </Dropdown>
                  </div>
                  
                </Col>
              </Row>
              
                <table className="pdfTable" style={{marginTop: '20px',overflowX: 'scroll',whiteSpace: 'nowrap',borderRight: 'none'}}>
                <tbody>
                  <tr>
                    <td style={{fontWeight:'bold'}}>ACCOUNT NAME</td>
                    <td></td>
                    <td style={{padding: '0',textAlign: 'center',borderRight: '1px solid #ddd'}}>
                    {
                      timeArr && timeArr.map((item,index)=>{
                        return (
                          <div className='balanceInfo' key={index+'ittime'} style={{minWidth:'130px',display:'inline-block', fontWeight:'bold',borderRight: '1px solid #ddd',padding: '10px'}}>{item}</div>
                        )
                      })
                    }
                    </td>
                  </tr>
                  {Object.keys(italkiWalletBalaceDetail).map((item,titleindex)=>{
                    return(
                      <React.Fragment key={'itWaBalance'+titleindex}>
                      <tr key={titleindex+'itwaop'}>
                        <td rowSpan="4" className="text-bold">{item}</td>
                        <td className="text-500">Opening Balance</td>
                        <td className="text-500" style={{padding: '0',borderRight: '1px solid #ddd'}}>{italkiWalletBalaceDetail[item].map((i,index)=>{
                          return(
                            <div className={`${index>1 ? 'balanceInfo': ''} `} key={index+'itwatd'} style={{minWidth:'130px',display:'inline-block',borderRight: '1px solid #ddd',padding: '12px 10px',textAlign: 'right'}} >
                              <div>$ {this.props.toThousands(i.opening_balance)}</div>
                            </div>
                            )
                        })}</td>
                      </tr>
                      <tr key={titleindex+'itawainitc'}>
                        <td>+</td>
                        <td style={{padding: '0',borderRight: '1px solid #ddd'}}>{italkiWalletBalaceDetail[item].map((i,index)=>{
                            return(
                              <div className={`${index>1 ? 'balanceInfo': ''} `} key={index+'itwatd'} style={{minWidth:'130px',display:'inline-block',borderRight: '1px solid #ddd',padding: '12px 10px',textAlign: 'right'}} >
                                <div className={`${i.increase_itc>0 ? 'canClick': ''}`} onClick={()=>{this.linkToTrans(item,'increase',index,i)}}>$ {this.props.toThousands(i.increase_itc)}</div>
                              </div>
                              )
                          })}</td>
                      </tr>
                      <tr key={titleindex+'itawadeitc'}>
                        <td>-</td>
                        <td style={{padding: '0',borderRight: '1px solid #ddd'}}>{italkiWalletBalaceDetail[item].map((i,index)=>{
                            return(
                              <div className={`${index>1 ? 'balanceInfo': ''} `} key={index+'itwatd'} style={{minWidth:'130px',display:'inline-block',borderRight: '1px solid #ddd',padding: '12px 10px',textAlign: 'right'}} >
                                <div className={`${i.decrease_itc>0 ? 'canClick': ''}`} onClick={()=>{this.linkToTrans(item,'decrease',index,i)}}>$ {this.props.toThousands(i.decrease_itc)}</div>
                              </div>
                              )
                          })}</td>
                      </tr>
                      <tr key={titleindex+'itwatr'}>
                          <td className="text-500">Closing Balance</td>
                          <td className="text-500" style={{padding: '0',borderRight: '1px solid #ddd'}}>{italkiWalletBalaceDetail[item].map((i,index)=>{
                            return(
                              <div className={`${index>1 ? 'balanceInfo': ''} `} key={index+'itwatd'} style={{minWidth:'130px',display:'inline-block',borderRight: '1px solid #ddd',padding: '12px 10px',textAlign: 'right'}} >
                                <div>$ {this.props.toThousands(i.closing_balance)}</div>
                              </div>
                              )
                          })}</td>
                      </tr>
                      </React.Fragment>
                    )
                  })}
                  </tbody>
              </table>
           </div>
       )
   }
}
export default ItalkiWalletBalance;