import React, { useMemo } from 'react'
import { Select } from 'antd'
import { useData } from '../data.js'

export default function _Seletor(props) {
  const [_data, loading] = useData()

  const _props = useMemo(() => ({
    allowClear: true,
    showSearch: true,
    filterOption: (input, option) => `${option.props.children.toLowerCase()} ${option.props.value.toString()}`.includes(input.toLowerCase()),
    ...props
  }), [props])

  return (
    <Select {..._props} loading={loading}>
      {_data.map(({ value, label }) => <Select.Option key={value} value={value}>{label}</Select.Option>)}
    </Select>
  )
}
