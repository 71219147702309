import { useEffect, useState } from "react";
import { getPaymentMethodConfig } from "../GetConfig";

export default getPaymentMethodConfig

let _data = []
export function useData() {
  const [data, setData] = useState(() => _data)
  const [loading, setLoading] = useState(() => !Array.isArray(_data) || _data.length === 0)

  useEffect(() => {
    if (!Array.isArray(_data) || _data.length === 0) {
      setLoading(true)

      getPaymentMethodConfig().then(d => {
        setData(d)
        _data = d
      }).finally(() => setLoading(false))
    }
  }, [])

  return [data, loading]
}
