import axios from "axios";
import qs from "qs";
import Cookies from "js-cookie";
let api = axios.create({
  headers: {
    "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    "X-Token": localStorage.getItem("afsToken"),
  },
});
api.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// http request 拦截器
api.interceptors.request.use(
  (config) => {
    config.data = qs.stringify(config.data);
    const newConfg = {
      ...config,
      headers: {
        ...config.headers,
        "X-Token": localStorage.getItem("afsToken"),
      },
    };
    return newConfg;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// 错误拦截
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response.status === 401) {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("afsToken");
      Cookies.remove("isLogin");
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);
export default api;
