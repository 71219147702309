import React, { Component } from 'react';
import '../../Balance/Balance.css';
import moment from 'moment';

class StWalletMovementIc extends Component {
    linkToTrans = (type,itc,item) => {
        let month = this.props.month < 10 ?  '0' + this.props.month.toString() : this.props.month.toString();
        if(itc > 0){
            if(type === 'topTitle'){
                this.props.history.push({ pathname: "/transactions/credit-purchases", state: { startYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'), endYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), paymentStatus: 1}});
            }else{
                this.props.history.push({ pathname: "/transactions/credit-purchases", state: { startYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).startOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).startOf('month').format('YYYY-MM-DD'), endYmd: this.props.isChoosedYear ? moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD') : moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'), paymentStatus: 1, paymentMethod: item}});
            }
        }
        console.log('this.props.isChoosedYear',this.props.isChoosedYear,moment(`${this.props.year}`).endOf('year').format('YYYY-MM-DD'),this.props,moment(`${this.props.year.toString()}-${month}`).endOf('month').format('YYYY-MM-DD'))

        
    }

    render(){
      return (
        <div>
            <table width='100%'>
            <tbody>
            <tr>
                <th colSpan='2' className="top-title">Student Wallets (BOS included)</th>
            </tr>
            <tr>
                <td className="text-bold bg-ddd">Opening Balance</td>
                <td className="text-bold bg-ddd text-right">${this.props.toThousands(this.props.stWalletMovement.opening_balance)}</td>
            </tr>
            <tr>
                <td className="text-bold bg-lightddd">Increase</td>
                <td className="text-bold bg-lightddd text-right">${this.props.toThousands(this.props.stWalletMovement.increase)}</td>
            </tr>
            <tr>
                <td className="text-500">Credit Purchases(Cash)</td>
                <td className={`text-500 text-right ${this.props.stWalletMovement.in_purchase>0 ? 'canClick' : ''}`} onClick={()=>{this.linkToTrans('topTitle',this.props.stWalletMovement.in_purchase)}}>${this.props.toThousands(this.props.stWalletMovement.in_purchase)}</td>
            </tr>
            {
            this.props.stWalletMovement.in_purchases_dic && Object.keys(this.props.stWalletMovement.in_purchases_dic).map((item,i)=>{
                return (
                <tr key={i+'stwaip'} className="color-light">
                <td>- {item}</td>
                <td className={`text-right ${this.props.stWalletMovement.in_purchases_dic[item]>0 ? 'canClick' : ''}`} onClick={()=>{this.linkToTrans('childTitle',this.props.stWalletMovement.in_purchases_dic[item],item)}}>${this.props.toThousands(this.props.stWalletMovement.in_purchases_dic[item])}</td>
                </tr>)
                })
            }
            <tr>
                <td className="text-500">From italki Wallets</td>
                <td className="text-500 text-right">${this.props.toThousands(this.props.stWalletMovement.from_sub_wallet.total_itc)}</td>
            </tr>
            {
                this.props.stWalletMovement.from_sub_wallet.account_dic && Object.keys(this.props.stWalletMovement.from_sub_wallet.account_dic).map((item,i)=>{
                return (
                <tr key={i+'stwafs'} className="color-light">
                    <td>- {item}</td>
                    <td className="text-right">${this.props.toThousands(this.props.stWalletMovement.from_sub_wallet.account_dic[item])}</td>
                </tr>)
                })
                }
                <tr>
                <td className="text-500">From Teacher Wallets</td>
                <td className="text-500 text-right">${this.props.toThousands(this.props.stWalletMovement.from_teacher+this.props.stWalletMovement.from_diff_user)}</td>
                </tr>
                <tr className="color-light">
                <td>- Same User Transfer</td>
                <td className="text-right">${this.props.toThousands(this.props.stWalletMovement.from_teacher)}</td>
                </tr>
                <tr className="color-light">
                <td>- Different User Transfer</td>
                <td className="text-right">${this.props.toThousands(this.props.stWalletMovement.from_diff_user)}</td>
                </tr>
                <tr>
                <td className="text-500">From Affiliate Wallets</td>
                <td className="text-500 text-right">${this.props.toThousands(this.props.stWalletMovement.from_affiliate)}</td>
                </tr>
            </tbody>
            
            </table>
        </div>
    )  
    }
    
}

export default StWalletMovementIc;