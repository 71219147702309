import React, {useState, useEffect} from 'react'
import { Table, DatePicker, Row, Col, Select, Button, Spin, notification } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import api from '../Api';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface AffWithdraList {
    key?: number;
    account_type_name: string;
    create_time: string;
    order_id: string;
    status: number;
    usd: string;
    user_id: number;
    [propNames: string]: any;
}
interface PostParams {
    start_ymd: number|string;
    end_ymd: number|string;
    // status: number;
    account_type?: number;
    page: number;
    page_size: number;
    get_total?: number;
    total?: number;
    download_csv?: number;
}

interface PropsInfo {
    [propNames: string]: any;
}

type PageObj = {
    page_size: number;
    page: number;
}

const withdrawMethod = [
    {0: "Bank Transfer"},
    {2: "PayPal"},
    {3: "Alipay"},
    {5: "WebMoney"},
    {7: "Moneybookers eWallet(Skrill)"},
    {30: "Bank Transfer(Payoneer)"},
    {31: "Payoneer"},
    {40: "PayPal (Hyperwallet)"},
    {41: "Bank Transfer (Hyperwallet)"}
]

const AffiliateWithdrawals:React.FC<{}> = (props:PropsInfo) => {
    const [ start_ymd, setStart_ymd ] = useState<any>(moment().month(moment().month() - 1).startOf('month'));
    const [ end_ymd, setEnd_ymd ] = useState<any>(moment().month(moment().month() - 1).endOf('month'));
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ withdrawType, setWithdrawType ] = useState<string>('-1');
    // const [ withdrawStatus, setWithdrawStatus ] = useState<string>('1');
    const [ teaTransList, setTeaTransList ] = useState<AffWithdraList[]>([]);
    const [ totalItc, setTotalItc ] = useState<number>();
    const [ pageObj, setPageObj ] = useState<PageObj>({page_size: 50, page:1})
    const [ dataTotal, setDataTotal ] = useState<number>()
      
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: false,
        showTotal: () => `Total: $ ${props.toThousands(totalItc)}, 共${dataTotal}条`,
        pageSize: pageObj.page_size,
        current: pageObj.page,
        total: dataTotal,
        onChange: (current:number) => changePage(current),
    }

    function getData(page: number, type?: string){
        setLoading(true);
        let params:PostParams = {
            start_ymd: start_ymd.format('YYYY-MM-DD').replace(/-/g,''),
            end_ymd: end_ymd.format('YYYY-MM-DD').replace(/-/g,''),
            // status: parseInt(withdrawStatus),
            account_type: parseInt(withdrawType),
            page: page,
            page_size: 50,
            get_total: 1
        }
        if(dataTotal && type !== 'searchData') {
            params['get_total'] = 0;
            params['total'] = dataTotal;
        }
        api.get('/transaction/affiliate-withdrawals',{params}).then(
            res =>{
                setLoading(false);
                let resData = res.data;
                setTeaTransList(resData.data.data_list);
                setPageObj(resData.paging);
                if(type !== "changePage"){
                    setDataTotal(resData.paging.total)
                }
                if(params['get_total'] === 1){
                    setTotalItc(resData.data.total_itc);
                }
                // if(type === 'searchData'){
                //     notification.success({
                //         message: 'Success',
                //     });
                // }
            }
        ).catch(
            err => {
                setLoading(false);
                notification.error({
                  message: 'Error',
                  description: err.response.data.msg
                });
                if(err.response.data.code === 401){
                  localStorage.removeItem('userInfo');
                  localStorage.removeItem('afsToken');
                  Cookies.remove('isLogin');
                  props.history.push('/login');
                }
              }
        )
    }
      
      useEffect(() => { 
        function getDefaultData(){
            setLoading(true);
            let params:PostParams = {
                start_ymd: moment().month(moment().month() - 1).startOf('month').format('YYYY-MM-DD').replace(/-/g,''),
                end_ymd: moment().month(moment().month() - 1).endOf('month').format('YYYY-MM-DD').replace(/-/g,''),
                // status: 1,
                account_type: -1,
                page: 1,
                page_size: 50,
                get_total: 1
            }
            api.get('/transaction/affiliate-withdrawals',{params}).then(
                res =>{
                    setLoading(false);
                    let resData = res.data;
                    resData.data.data_list.forEach((item:any,index:number)=>{
                        item['key'] = index + item.order_id
                    })
                    setTeaTransList(resData.data.data_list);
                    setTotalItc(resData.data.total_itc);
                    setPageObj(resData.paging);
                    setDataTotal(resData.paging.total)
                    // notification.success({
                    //     message: 'Success',
                    // });
                    
                }
            ).catch(
                err => {
                    setLoading(false);
                    notification.error({
                      message: 'Error',
                      description: err.response.data.msg
                    });
                    if(err.response.data.code === 401){
                      localStorage.removeItem('userInfo');
                      localStorage.removeItem('afsToken');
                      Cookies.remove('isLogin');
                      props.history.push('/login');
                    }
                  }
            )
        }
        getDefaultData()
    }, [props.history])

    function changePage(current: number){
        getData(current,"changePage")
    }

    function changeDate(value: any){
        if(value.length > 0){
            setStart_ymd(value[0]);
            setEnd_ymd(value[1]);
        }else{
            setStart_ymd('');
            setEnd_ymd('');
        }
        
    }

    function disabledDate(current: any) {
        return current && (current > moment().endOf('day') || current < moment(new Date('2008/11/01')).endOf('day'));
      }

    function searchData(){
        getData(1,'searchData');
    }

    return (
        <div>
            <Spin size="large" spinning={loading} className="loading"/>
            <div>
                <Row gutter={16}>
                    <Col span={8} className="padding-0-5">
                        <p>Date</p>
                        <RangePicker
                            style={{width: '100%'}}
                            ranges={{
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                            'Last 30 Days': [moment().subtract(30, 'day'), moment()],
                            'This Year': [moment().startOf('year'), moment().endOf('year')],
                            'Last Year': [moment().year(moment().year() - 1).startOf('year'), moment().year(moment().year() - 1).endOf('year')]
                            }}
                            defaultValue={[start_ymd, end_ymd]}
                            disabledDate={(e)=>disabledDate(e)}
                            showTime={false}
                            onChange={(e)=>{changeDate(e)}}
                        />
                    </Col>
                    {/* <Col span={8} className="padding-0-5">
                        <p>Status</p>
                        <Select defaultValue={withdrawStatus} style={{width: '100%'}} 
                        onChange={(e:string)=>setWithdrawStatus(e)}
                        >
                            <Option value='1'>Withdrawal reversed</Option>
                            <Option value='-1'>Withdrawal</Option>
                        </Select>
                    </Col> */}
                    <Col span={8} className="padding-0-5">
                        <p>Account Type</p>
                        <Select value={withdrawType} style={{width: '100%'}} onChange={(e:string)=>{setWithdrawType(e)}}>
                            <Option value='-1'>All</Option>
                            {
                                withdrawMethod.map((item,index)=>{
                                    return (
                                        <Option key={`${Object.values(item)[0]}withdrawMethod`} value={Object.keys(item)[0]}>{Object.values(item)[0]}</Option>
                                    )
                                })
                            }
                        </Select>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <p>&nbsp;</p>
                        <Button type="primary" disabled={!start_ymd||!end_ymd} className='float-right' onClick={searchData}>View</Button>
                    </Col>    
                </Row>
            </div>  
            <Table<AffWithdraList> dataSource={teaTransList} pagination={paginationProps}>
                <Table.Column<AffWithdraList> key="create_time" title="DATE" 
                render = {(AffWithdraList) => (
                <div>
                    {moment(AffWithdraList.create_time).utc().format('YYYY-MM-DD HH:mm:ss')}
                </div>
            ) }/>
                <Table.Column<AffWithdraList> key="order_id" title="ORDER ID" dataIndex="order_id" />
                <Table.Column<AffWithdraList> key="usd" title="USD" dataIndex="usd" />
                <Table.Column<AffWithdraList> key="user_id" title="USER ID" dataIndex="user_id" />
                <Table.Column<AffWithdraList> key="account_type_name" title="ACCOUNT TYPE" dataIndex="account_type_name" />
                <Table.Column<AffWithdraList> key="status" title="STATUS" 
                    render = {(AffWithdraList) => (
                        <div>
                            {AffWithdraList.status === 1 ? 'Withdrawal reversed' : 'Withdrawal'}
                        </div>
                    ) }
                />
            </Table>        
        </div>
    )
}

export default AffiliateWithdrawals;