import React, { Component } from 'react';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() { 
        return ( 
            <div></div>
         );
    }
}
 
export default Dashboard;