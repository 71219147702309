import api from "../../components/Api";

export default function _base(name, resolveFn) {
  return api.get(`/system/get-config?name=${name}`).then(
    res => resolveFn ? resolveFn(res.data.data) : res.data.data
  ).catch(
    err => {
      console.log('get-config error', err)
      return []
    }
  )
};
