import { Form, Icon, Input, Button, notification } from 'antd';
import React, { Component } from 'react';
import api from '../Api';
import Cookies from 'js-cookie';


class ModifyPasswordForm extends Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let postData = {old_pwd: values.oldPassword,new_pwd: values.password};
        api.post('/change-pwd',postData).then(
          res => {
            this.props.form.resetFields();
            notification.info({
              message: 'Success'
            });
          }
        ).catch(
          err =>{
            notification.error({
              message: 'Error',
              description: err.response?.data.msg
            });
            if(err.response?.data.code === 401){
              localStorage.removeItem('userInfo');
              localStorage.removeItem('afsToken');
              Cookies.remove('isLogin');
              this.props.history.push('/login');
            }
          }
        )
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const oldPasswordError = isFieldTouched('oldPassword') && getFieldError('oldPassword');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    const confirmPasswordError = isFieldTouched('confirmPassword') && getFieldError('password');

    return (
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit} style={{ width: '700px'}}>
        <Form.Item label="Old Password" validateStatus={oldPasswordError ? 'error' : ''} help={oldPasswordError || ''}>
          {getFieldDecorator('oldPassword', {
            rules: [{ required: true, message: 'Please input your oldPassword!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              autoComplete="off" 
            />,
          )}
        </Form.Item>
        <Form.Item label="New Password" validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              autoComplete="off" 
            />,
          )}
        </Form.Item>
        <Form.Item label="Confirm Password" validateStatus={confirmPasswordError ? 'error' : ''} help={confirmPasswordError || ''}>
          {getFieldDecorator('confirmPassword', {
            rules: [{ required: true, message: 'Please confirm your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              autoComplete="off" 
            />,
          )}
           <span style={{color: 'red'}}>(Length of password must be at least 6)</span>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
          <Button type="primary" disabled={!this.props.form.getFieldValue('oldPassword')||!this.props.form.getFieldValue('password')||(this.props.form.getFieldValue('password')!==this.props.form.getFieldValue('confirmPassword'))} htmlType="submit">
            Submit
          </Button>
          <Button type="primary" onClick={()=>{this.props.form.resetFields()}} htmlType="reset" style={{marginLeft: '20px'}}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(ModifyPasswordForm);

