import React,{ Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "./components/Login";
import AuthRouter from './components/AuthRouter';
import LoginRouter from './components/LoginRouter';
import LayoutContent from './components/Layout';

class App extends Component {
 
  render() {
    return (
      <Router className="app">
        <Switch>
          <LoginRouter path="/login" component={Login} />
          <AuthRouter path="/" component={LayoutContent} />
        </Switch>
      </Router>
    );
  }
}

export default App;          