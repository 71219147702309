import React, { Component } from 'react';
class ItaWalletMovementIc extends Component {

    isHaveNum = (str) => {
        let haveNum = new RegExp(/[0-9]/);
        if (haveNum.test(str)) {
            return true;
        } else {
            return false;
        }
    }

    linkToTrans = (i, type, toptitle) => {
        let walletId = i.replace(/[^0-9]/ig, "");
        let topWalletId;
        if (toptitle) {
            topWalletId = toptitle.replace(/[^0-9]/ig, "")
        }
        let month = this.props.month < 10 ? '0' + this.props.month.toString() : this.props.month.toString();
        if (type === 'childtitle') {
            if (walletId > 0) {
                this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: topWalletId, date: this.props.isChoosedYear ? this.props.year.toString() : this.props.year.toString() + month, inout: 1, relate_wallet_id: walletId, } });
            } else {
                if (i === 'Student Wallet') {
                    this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: topWalletId, date: this.props.isChoosedYear ? this.props.year.toString() : this.props.year.toString() + month, inout: 1, relate_wallet_scope: 'USER', relate_wallet_type: '0' } });
                } else if (i === 'Teacher Wallet') {
                    this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: topWalletId, date: this.props.isChoosedYear ? this.props.year.toString() : this.props.year.toString() + month, inout: 1, relate_wallet_scope: 'USER', relate_wallet_type: '1' } });
                } else if (i === 'Affiliate Wallet') {
                    this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: topWalletId, date: this.props.isChoosedYear ? this.props.year.toString() : this.props.year.toString() + month, inout: 1, relate_wallet_scope: 'USER', relate_wallet_type: '2' } });
                } else if (i === '(external)') {
                    this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: topWalletId, date: this.props.isChoosedYear ? this.props.year.toString() : this.props.year.toString() + month, inout: 1, relate_wallet_id: '0' } });
                }
            }
        } else {
            this.props.history.push({ pathname: "/transactions/italki-wallet-credit-transactions", state: { wallet_id: walletId, date: this.props.isChoosedYear ? this.props.year.toString() : this.props.year.toString() + month, inout: 1 } });
        }


    }

    render() {
        return (
            <div>
                <table width='100%'>
                    <tbody>
                        <tr>
                            <td className="text-bold bg-lightddd" style={{width:"50%",borderLeft:"0.5px solid #cccccc",borderBottom:"0.5px solid #cccccc"}}>Increase</td>
                            <td className="text-bold bg-lightddd" style={{borderLeft:"0.5px solid #cccccc",borderBottom:"0.5px solid #cccccc",borderRight:"1px solid #cccccc"}}>${this.props.toThousands(this.props.itaWalletMovement.increase)}</td>
                        </tr>
                        {
                            this.props.itaWalletMovement.from_wallets && Object.keys(this.props.itaWalletMovement.from_wallets).map((item, i) => {
                                return (
                                    <React.Fragment key={i + 'iwfw'} >
                                        <tr>
                                            <td className="text-500">{item}</td>
                                            <td className="text-500  canClick" onClick={() => { this.linkToTrans(item, 'toptitle') }}>${this.props.toThousands(this.props.itaWalletMovement.from_wallets[item].total_itc)}</td>
                                        </tr>
                                        {
                                            this.props.itaWalletMovement.from_wallets[item].account_dic && Object.keys(this.props.itaWalletMovement.from_wallets[item].account_dic).map((v, i) => {
                                                return (
                                                    <tr key={i + 'iwfa'} className="color-light">
                                                        <td>- {v}</td>
                                                        <td className="canClick" onClick={() => { this.linkToTrans(v, 'childtitle', item) }}>${this.props.toThousands(this.props.itaWalletMovement.from_wallets[item].account_dic[v])}</td>
                                                    </tr>
                                                )
                                            })

                                        }
                                    </React.Fragment>)
                            })
                        }
                    </tbody>

                </table>
            </div>
        )
    }
}
export default ItaWalletMovementIc;