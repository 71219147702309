import React from 'react';
import '../../Balance/Balance.css';

const AfWalletMovementDe = props =>{
    return (
        <div>
            <table width='100%'>
                <tbody>
            <tr>
                <td className="text-bold bg-lightddd">Decrease</td>
                <td className="text-bold bg-lightddd text-right">${props.toThousands(props.afWalletMovement.decrease)}</td>
            </tr>
            <tr>
                <td className="text-500">Withdrawal to Cash</td>
                <td className="text-500 text-right">${props.toThousands(props.afWalletMovement.withdraw_cash)}</td>
            </tr>
            <tr>
                <td className="text-500">To Student Wallets</td>
                <td className="text-500 text-right">${props.toThousands(props.afWalletMovement.to_student)}</td>
            </tr>
            <tr>
                <td className="text-500">To Teacher Wallets</td>
                <td className="text-500 text-right">${props.toThousands(props.afWalletMovement.to_teacher)}</td>
            </tr>         
                </tbody>
            
            </table>
        </div>
    )
}
export default AfWalletMovementDe;