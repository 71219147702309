import React, {useState, useEffect} from 'react'
import { Table, DatePicker, Row, Col, Select, Button, Spin, notification } from 'antd';
import Cookies from 'js-cookie';
import moment from 'moment';
import api from '../Api';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface TeaTransList {
    key?: number;
    create_time: string;
    order_id: string;
    usd: string;
    student_id: number;
    teacher_id: number;
    inout: number;
    [propNames: string]: any;
}
interface PostParams {
    start_ymd: number|string;
    end_ymd: number|string;
    inout?: number;
    same_user?: number;
    page: number;
    page_size: number;
    get_total?: number;
    total?: number;
    download_csv?: number;
}

interface PropsInfo {
    [propNames: string]: any;
}

type PageObj = {
    page_size: number;
    page: number;
}

const TeacherTransfers:React.FC<{}> = (props:PropsInfo) => {
    const [ start_ymd, setStart_ymd ] = useState<any>(props.location.state ? moment(props.location.state.startYmd) : moment().month(moment().month() - 1).startOf('month'));
    const [ end_ymd, setEnd_ymd ] = useState<any>(props.location.state ? moment(props.location.state.endYmd) : moment().month(moment().month() - 1).endOf('month'));
    const [ inout, setInout ] = useState<string>(props.location.state ? props.location.state.transferType.toString() : '-1');
    const [ same_user, setSame_user ] = useState<string>(props.location.state ? props.location.state.sameUser.toString() : '0');
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ teaTransList, setTeaTransList ] = useState<TeaTransList[]>([]);
    const [ totalItc, setTotalItc ] = useState<number>();
    const [ pageObj, setPageObj ] = useState<PageObj>({page_size: 50, page:1})
    const [ dataTotal, setDataTotal ] = useState<number>()
      
    const paginationProps = {
        showSizeChanger: false,
        showQuickJumper: false,
        showTotal: () => `Total: $ ${props.toThousands(totalItc)}, 共${dataTotal}条`,
        pageSize: pageObj.page_size,
        current: pageObj.page,
        total: dataTotal,
        onChange: (current:number) => changePage(current),
    }

    function getData(page: number, type?: string){
        setLoading(true);
        let params:PostParams = {
            start_ymd: start_ymd.format('YYYY-MM-DD').replace(/-/g,''),
            end_ymd: end_ymd.format('YYYY-MM-DD').replace(/-/g,''),
            same_user: parseInt(same_user),
            inout: parseInt(inout),
            page: page,
            page_size: 50,
            get_total: 1
        }
        if(dataTotal && type !== 'searchData') {
            params['get_total'] = 0;
            params['total'] = dataTotal;
        }
        api.get('/transaction/teacher-transfers',{params}).then(
            res =>{
                setLoading(false);
                let resData = res.data;
                setTeaTransList(resData.data.data_list);
                setPageObj(resData.paging);
                if(type !== "changePage"){
                    setDataTotal(resData.paging.total)
                }
                if(params['get_total'] === 1){
                    setTotalItc(resData.data.total_itc);
                }
                // if(type === 'searchData'){
                //     notification.success({
                //         message: 'Success',
                //     });
                // }
            }
        ).catch(
            err => {
                setLoading(false);
                notification.error({
                  message: 'Error',
                  description: err.response.data.msg
                });
                if(err.response.data.code === 401){
                  localStorage.removeItem('userInfo');
                  localStorage.removeItem('afsToken');
                  Cookies.remove('isLogin');
                  props.history.push('/login');
                }
              }
        )
    }
      
      useEffect(() => { 
        let startYmd:string;
        let endYmd:string;
        let transferType:number;
        let isSameUser:number;
        if(props.location.state){
            startYmd = props.location.state.startYmd;
            endYmd = props.location.state.endYmd;
            transferType = props.location.state.transferType; 
            isSameUser = props.location.state.sameUser; 
        }
        function getDefaultData(){
            setLoading(true);
            let params:PostParams = {
                start_ymd: startYmd ? startYmd.replace(/-/g,'') : moment().month(moment().month() - 1).startOf('month').format('YYYY-MM-DD').replace(/-/g,''),
                end_ymd: endYmd ? endYmd.replace(/-/g,'') : moment().month(moment().month() - 1).endOf('month').format('YYYY-MM-DD').replace(/-/g,''),
                inout: transferType ? transferType : -1,
                page: 1,
                page_size: 50,
                get_total: 1
            }
            if(isSameUser) params['same_user'] = isSameUser;
            api.get('/transaction/teacher-transfers',{params}).then(
                res =>{
                    setLoading(false);
                    let resData = res.data;
                    resData.data.data_list.forEach((item:any,index:number)=>{
                        item['key'] = index + item.order_id
                    })
                    setTeaTransList(resData.data.data_list);
                    setTotalItc(resData.data.total_itc);
                    setPageObj(resData.paging);
                    setDataTotal(resData.paging.total)
                    // notification.success({
                    //     message: 'Success',
                    // });
                    
                }
            ).catch(
                err => {
                    setLoading(false);
                    notification.error({
                      message: 'Error',
                      description: err.response.data.msg
                    });
                    if(err.response.data.code === 401){
                      localStorage.removeItem('userInfo');
                      localStorage.removeItem('afsToken');
                      Cookies.remove('isLogin');
                      props.history.push('/login');
                    }
                  }
            )
        }
        getDefaultData()
    }, [props])

    function changePage(current: number){
        getData(current,"changePage")
    }

    function changeDate(value: any){
        if(value.length > 0){
            setStart_ymd(value[0]);
            setEnd_ymd(value[1]);
        }else{
            setStart_ymd('');
            setEnd_ymd('');
        }
        
    }

    function disabledDate(current: any) {
        return current && (current > moment().endOf('day') || current < moment(new Date('2008/11/01')).endOf('day'));
      }

    function searchData(){
        getData(1,'searchData');
    }

    return (
        <div>
            <Spin size="large" spinning={loading} className="loading"/>
            <div>
                <Row gutter={16}>
                    <Col span={8} className="padding-0-5">
                        <p>Date</p>
                        <RangePicker
                            style={{width: '100%'}}
                            ranges={{
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().month(moment().month() - 1).startOf('month'), moment().month(moment().month() - 1).endOf('month')],
                            'Last 30 Days': [moment().subtract(30, 'day'), moment()],
                            'This Year': [moment().startOf('year'), moment().endOf('year')],
                            'Last Year': [moment().year(moment().year() - 1).startOf('year'), moment().year(moment().year() - 1).endOf('year')]
                            }}
                            defaultValue={[start_ymd, end_ymd]}
                            disabledDate={(e)=>disabledDate(e)}
                            showTime={false}
                            onChange={(e)=>{changeDate(e)}}
                        />
                    </Col>
                    <Col span={8} className="padding-0-5">
                        <p>Transfer Type</p>
                        <Select defaultValue={inout} style={{width: '100%'}} 
                        onChange={(e:string)=>setInout(e)}
                        >
                            <Option value='-1'>To Student Wallets</Option>
                            <Option value='1'>From Student Wallets</Option>
                        </Select>
                    </Col>
                    <Col span={8} className="padding-0-5">
                        <p>Same User</p>
                        <Select defaultValue={same_user} style={{width: '100%'}} onChange={(e:string)=>setSame_user(e)}>
                            <Option value='0'>All</Option>
                            <Option value='1'>Same User Transfer</Option>
                            <Option value='2'>Different User Transfer</Option>
                        </Select>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <p>&nbsp;</p>
                        <Button type="primary" disabled={!start_ymd||!end_ymd} className='float-right' onClick={searchData}>View</Button>
                    </Col>    
                </Row>
            </div>  
            <Table<TeaTransList> dataSource={teaTransList} pagination={paginationProps}>
                <Table.Column<TeaTransList> key="create_time" title="DATE" 
                render = {(TeaTransList) => (
                <div>
                    {moment(TeaTransList.create_time).utc().format('YYYY-MM-DD HH:mm:ss')}
                </div>
            ) }/>
                <Table.Column<TeaTransList> key="order_id" title="ORDER ID" dataIndex="order_id" />
                <Table.Column<TeaTransList> key="usd" title="USD" dataIndex="usd" />
                <Table.Column<TeaTransList> key="teacher_id" title="TEACHER ID" dataIndex="teacher_id" />
                <Table.Column<TeaTransList> key="student_id" title="STUDENT ID" dataIndex="student_id" />
                <Table.Column<TeaTransList> key="inout" title="TRANSFER TYPE" 
                render = {(TeaTransList) => (
                    <div>
                        {TeaTransList.inout === 1 ? 'From Student Wallets' : 'To Student Wallets'}
                    </div>
                ) }
                />
            </Table>        
        </div>
    )
}

export default TeacherTransfers;