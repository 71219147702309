import React from 'react';
import '../../Balance/Balance.css';

const ItaWalletMovementCB = props =>{
    return (
        <div>
            <table width='100%'>
                <tbody>
                    <tr>
                        <td className="text-bold bg-ddd" style={{width:"50%"}}>Closing Balance<br/>(OB+Incr-Decr)</td>
                        <td className="text-bold bg-ddd" style={{color: props.itaWalletMovement.closing_balance_figure_up !== props.itaWalletMovement.closing_balance ? 'red' : ''}}>${props.toThousands(props.itaWalletMovement.closing_balance_figure_up)}</td>
                    </tr>
                    <tr>
                        <td className="text-bold bg-ddd">Closing Balance<br/>(Snapshot)</td>
                        <td className="text-bold bg-ddd">${props.toThousands(props.itaWalletMovement.closing_balance)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default ItaWalletMovementCB;