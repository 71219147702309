import React from 'react';
import '../../Balance/Balance.css';

const AfWalletMovement = props =>{
    return (
        <div>
            <table width='100%'>
                <tbody>
                <tr>
                    <th colSpan='2' className="top-title">Affiliate Wallets</th>
                </tr>
                <tr>
                    <td className="text-bold bg-ddd">Opening Balance</td>
                    <td className="text-bold bg-ddd text-right">${props.toThousands(props.afWalletMovement.opening_balance)}</td>
                </tr>
                <tr>
                    <td className="text-bold bg-lightddd">Increase</td>
                    <td className="text-bold bg-lightddd text-right">${props.toThousands(props.afWalletMovement.increase)}</td>
                </tr>
                <tr>
                    <td className="text-500">From italki Wallets</td>
                    <td className="text-500 text-right">${props.toThousands(props.afWalletMovement.from_sub_wallet.total_itc)}</td>
                </tr>
                {
                    props.afWalletMovement.from_sub_wallet.account_dic && Object.keys(props.afWalletMovement.from_sub_wallet.account_dic).map((item,i)=>{
                    return (
                    <tr key={i+'awfs'} className="color-light">
                        <td>- {item}</td>
                        <td className="text-right">${props.toThousands(props.afWalletMovement.from_sub_wallet.account_dic[item])}</td>
                    </tr>)
                    })
                    }
                </tbody>
            
            </table>
        </div>
    )
}
export default AfWalletMovement;